//
// Page Styles: Zero Bleeds Home
// --------------------------------------------------

#main-container.homepage{

	.ios &{
		.download-links{
			.android{
				display: none;
			}
			.ios{
				width: 100%;
			}
		}
	}

	.android &{
		.download-links{
			.ios{
				display: none;
			}
			.android{
				width: 100%;
			}
		}
	}

	.form-control{
		font-size: 16px;
	}

	@media screen and (max-width: @screen-xs-max) {
		.features-block{
			margin-top: 0px!important;
		}

        .block{
        	min-height: auto!important;
        }

		.form-group > div{
			margin: 12px auto;
		}

        .form-horizontal{
        	padding-top: 0px!important;
        }

        .footer{
        	padding: 0 20px;
        }
    }

}