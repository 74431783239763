//
// Page Styles: Patient Overview
// --------------------------------------------------

.patient-header {
    @media screen and (max-width: @screen-xs-max) {
        .name{
            float: none!important;
        }

        .buttons.pull-right{
            float: none!important;
            margin-top: 0!important;
            width: 100%;
            display: block;

            .btn, .btn-group{
                margin: 3px 0;
            }
        }
    }
}

.widget-active-patients {
    .dateOverlay {
        width: 130px;
        height: 115px;
        margin: 0 auto;
        margin-top: -135px;
        margin-bottom: 30px;
        overflow: hidden;
        position: relative;
        p {
            margin: 0;
            padding: 0;
            width: 100%;
            text-align: center;
            &.nextInfusionDateDay,
            &.details {
                font-size: 5em;
                line-height: 85px;
                font-weight: 500;
            }
            &.nextInfusionDateMonth {
                font-size: 1.3em;
                font-weight: bold;
                text-transform: uppercase;
                position: absolute;
                top: 80px;
            }
            &.nextInfusionTime {
                margin-top: 20px;
                font-size: 2.1em;
                min-height: 24px;

                html:lang(zh) &{
                    font-size: 1.9em;
                }
            }
            &.nextInfusionTodayOrTomorrow {
                font-size: 1.3em;
                font-weight: bold;
                text-transform: uppercase;
                position: absolute;
                top: 70px;
            }
        }
    }
}
#calendar-title{
	width: 30%;
	display: inline-block;
}

// Calendar Legend / Margins
// We want to keep the calendar box roughly the same size, while still
// positioning the calendar legend at the bottom. Unfortuantely, we can't
// guarantee the height of the calendar, as the # of displayed week changes.
// Therefore, we add a margin to a regularly sized month, and remove it when we
// add a sixth row/week

.js-bootstrapCalendar{
  margin-bottom: 135px;

  .cal-row-fluid:nth-child(6){
    margin-bottom: -64px;
  }
}

.cal-legend{
    font-size: 16px;
    line-height: 22px;
    text-align: middle;

    @media screen and (min-width: @screen-xs-min) {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    height: 80px;
    min-height: 80px;

    div{
        display: inline-block;
        padding: 0 20px;
    }
    span.legend{
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin: 2px 8px 4px 2px;

        &.bleed{
            height: 3px;
            width: 18px;
            background: @blue;
        }

        .event-calendar-bold&{
            &.bleed{
                width: 16px;
                height: 16px;
                background: @red;
            }
        }

        &.infusion{
            background: @brand-infusion;
        }

        &.planned-infusion{
            background: @brand-user;
        }

        &.missed-infusion{
            border: 2px solid @purple;
        }
    }

    span.legend-text{
        white-space: nowrap;
    }

}

#notes-block{
    &.block-opt-fullscreen{
        max-height: none;

        .slimScrollDiv,
        .slimScrollDiv > div{
            height: auto!important;
        }
    }

    pre{
        padding: 0;
        margin: 0;
        font-size: inherit;
        line-height: 1.42857143;
        color: #333;
        word-break: break-word;
        word-wrap: break-word;
        background-color: transparent;
        border: none;
        border-radius: none;
          white-space: pre-wrap; 
          word-wrap: break-word;
          font-family: inherit;
    }
}

#alerts-block{
    .nav-users a{
        padding-top: 5px;
        padding-bottom: 4px;
        min-height: 83px;
    }
}

#eventLog{
    .nav-users a > i{
        top: 60px;
        z-index: 1;
        &.fa-calendar-plus-o{
            padding-left: 1px;
            line-height: 17px;
        }

        &:nth-of-type(2){
            .transform(scale(0.7), rotate(0deg));
            top: 51px;
            left: 45px;
            border: 1px solid white;
            z-index: 0;
        }
    }
}