//
// Color Theme - tenant
// --------------------------------------------------

// Variables and Mixins
@import "../variables.less";
@import "../mixins.less";

// Generate color theme

body.theme-tenant{
	.color-theme(@theme-tenant-base; @theme-tenant-light; @theme-tenant-lighter; @theme-tenant-dark; @theme-tenant-darker; @theme-tenant-body);
}

.text-tenant {
    .text-emphasis-variant(@theme-tenant-base);

    &-dark {
        .text-emphasis-variant(@theme-tenant-dark);
    }

    &-darker {
        .text-emphasis-variant(@theme-tenant-darker);
    }

    &-light {
        .text-emphasis-variant(@theme-tenant-light);
    }

    &-lighter {
        .text-emphasis-variant(@theme-tenant-lighter);
    }
}

.bg-tenant {
    .bg-variant(@theme-tenant-base);

    &-op {
        .bg-variant(fade(@theme-tenant-base, 75%));
    }

    &-dark {
        .bg-variant(@theme-tenant-dark);
    }

    &-dark-op {
        .bg-variant(fade(@theme-tenant-dark, 83%));
    }

    &-darker {
        .bg-variant(@theme-tenant-darker);
    }

    &-light {
        .bg-variant(@theme-tenant-light);
    }

    &-lighter {
        .bg-variant(@theme-tenant-lighter);
    }
}

.border-tenant {
    .border-variant(@theme-tenant-base);

    &-op {
        .border-variant(fade(@theme-tenant-base, 75%));
    }

    &-dark {
        .border-variant(@theme-tenant-dark);
    }

    &-dark-op {
        .border-variant(fade(@theme-tenant-dark, 83%));
    }

    &-darker {
        .border-variant(@theme-tenant-darker);
    }

    &-light {
        .border-variant(@theme-tenant-light);
    }

    &-lighter {
        .border-variant(@theme-tenant-lighter);
    }
}