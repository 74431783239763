//
// Color Theme - City
// --------------------------------------------------

// Variables and Mixins
@import "../variables.less";
@import "../mixins.less";

// Generate color theme
body.theme-city{
	.color-theme(@theme-city-base; @theme-city-light; @theme-city-lighter; @theme-city-dark; @theme-city-darker; @theme-city-body);
}