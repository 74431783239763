//
// Page Styles: Zero Bleeds Orders Dashboard
// --------------------------------------------------


// Order Managment Dashboard: Active Orders Table
// Disable the Column Header when it has no text
table#pendingOrders.dataTable,
table#confirmedOrders.dataTable,
table#activeOrders.dataTable,
table#completedOrders.dataTable {
	> tbody > tr.child {
		li {
			span.dtr-title:empty {
				display: none;
			}
			span.dtr-data{
				line-height: 32px;
			}
		}
		
		// Improve readability by increasing the border contrast on the next row
		// after a child element within these tables  
		+ tr > td{
			border-top: 1px solid #BEBEBE;
		}
	}
}

// Position the loading indicator on the patient-inventory-btn
table#allPatients {
	.patient-inventory-btn {
		&.loading {
			position: relative;
			opacity: 0.3;

			i{
				opacity: 0;
			}

			&:after{
				position: absolute;
				top: 50%;
				left: 17px;
				margin: -10px 0 0 -10px;
				width: 20px;
				height: 20px;
				line-height: 20px;
				color: @text-color;
				font-size: 14px;
				text-align: center;
				z-index: 2;
				font-family: 'FontAwesome';
				content: "\f1ce";
				.animation(fa-spin 2s infinite linear);
			}
		}
	}
}


#patientInventoryBlock{
	.block-content{
		overflow: hidden;
		.transition(max-height 0.6s ease-out);
	}
}


// Order Managment Dashboard: New Order Modal
// Disable individual item help text for the Add Products Table
#productListGroup{
	.help-block{
		display: none!important;
	}
}

// Delivery Details View
#main-container.delivery-dash {
	table.delivery-details{
		tr {
			// margin-bottom: 15px;
			td {
				padding: 5px 10px;
			}
		}

		tr > td:first-child{
			color: @brand-tenant;
			font-weight: 600;
			text-transform: uppercase;
			text-align: right;
		}

		tr > td:last-child{
			text-align: left;
		}
	}

	@media screen and (max-width: @screen-xs-max) {
		.page-header{
			display: none;
		}

		> .content-boxed{
			padding-left: 0;
			padding-right: 0;
		}

		.block-content{
			overflow: hidden;
		}
	}
}


#main-container.delivery-details {
	.page-header{
		text-align: center;
		div {
			margin-left: auto !important;
			margin-right: auto !important;
			width: 100%;

			&.pull-right{
				margin-top: 0!important;
				margin-bottom: 20px;
			}
		}
		h1 {
			width: 100%;
			margin: 10px 0;
			font-size: @font-size-h3;
		}
		a {
			width: 100%;
		}

		@media screen and (max-width: @screen-xs-max) {
			display: block;
		}
	}
}