//
// Color Theme - Amethyst
// --------------------------------------------------

// Variables and Mixins
@import "../variables.less";
@import "../mixins.less";

// Generate color theme

body.theme-amethyst{
	.color-theme(@theme-amethyst-base; @theme-amethyst-light; @theme-amethyst-lighter; @theme-amethyst-dark; @theme-amethyst-darker; @theme-amethyst-body);
}