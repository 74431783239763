//
// Color Theme - alert
// --------------------------------------------------

// Variables and Mixins
@import "../variables.less";
@import "../mixins.less";

// Generate color theme

body.theme-alert{
	.color-theme(@theme-alert-base; @theme-alert-light; @theme-alert-lighter; @theme-alert-dark; @theme-alert-darker; @theme-alert-body);
}

.text-alert {
    .text-emphasis-variant(@theme-alert-base);

    &-dark {
        .text-emphasis-variant(@theme-alert-dark);
    }

    &-darker {
        .text-emphasis-variant(@theme-alert-darker);
    }

    &-light {
        .text-emphasis-variant(@theme-alert-light);
    }

    &-lighter {
        .text-emphasis-variant(@theme-alert-lighter);
    }
}

.bg-alert {
    .bg-variant(@theme-alert-base);

    &-op {
        .bg-variant(fade(@theme-alert-base, 75%));
    }

    &-dark {
        .bg-variant(@theme-alert-dark);
    }

    &-dark-op {
        .bg-variant(fade(@theme-alert-dark, 83%));
    }

    &-darker {
        .bg-variant(@theme-alert-darker);
    }

    &-light {
        .bg-variant(@theme-alert-light);
    }

    &-lighter {
        .bg-variant(@theme-alert-lighter);
    }
}

.border-alert {
    .border-variant(@theme-alert-base);

    &-op {
        .border-variant(fade(@theme-alert-base, 75%));
    }

    &-dark {
        .border-variant(@theme-alert-dark);
    }

    &-dark-op {
        .border-variant(fade(@theme-alert-dark, 83%));
    }

    &-darker {
        .border-variant(@theme-alert-darker);
    }

    &-light {
        .border-variant(@theme-alert-light);
    }

    &-lighter {
        .border-variant(@theme-alert-lighter);
    }
}