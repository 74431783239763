//
// Color Theme - Smooth
// --------------------------------------------------

// Variables and Mixins
@import "../variables.less";
@import "../mixins.less";

// Generate color theme
body.theme-smooth{
	.color-theme(@theme-smooth-base; @theme-smooth-light; @theme-smooth-lighter; @theme-smooth-dark; @theme-smooth-darker; @theme-smooth-body);
}