body.theme-healthcareProfessional body {
  background-color: #f8f8f8;
}
body.theme-healthcareProfessional a {
  color: #00AAF0;
}
body.theme-healthcareProfessional a.link-effect:before {
  background-color: #0074a3;
}
body.theme-healthcareProfessional a:hover,
body.theme-healthcareProfessional a:focus {
  color: #0074a3;
}
body.theme-healthcareProfessional a:active {
  color: #00AAF0;
}
body.theme-healthcareProfessional .text-primary {
  color: #00AAF0;
}
abody.theme-healthcareProfessional .text-primary:hover,
abody.theme-healthcareProfessional .text-primary:active,
abody.theme-healthcareProfessional .text-primary:focus,
buttonbody.theme-healthcareProfessional .text-primary:hover,
buttonbody.theme-healthcareProfessional .text-primary:active,
buttonbody.theme-healthcareProfessional .text-primary:focus {
  color: #00AAF0;
  opacity: .75;
}
li abody.theme-healthcareProfessional .text-primary:hover {
  opacity: 1;
}
body.theme-healthcareProfessional .text-primary-dark {
  color: #898989;
}
abody.theme-healthcareProfessional .text-primary-dark:hover,
abody.theme-healthcareProfessional .text-primary-dark:active,
abody.theme-healthcareProfessional .text-primary-dark:focus,
buttonbody.theme-healthcareProfessional .text-primary-dark:hover,
buttonbody.theme-healthcareProfessional .text-primary-dark:active,
buttonbody.theme-healthcareProfessional .text-primary-dark:focus {
  color: #898989;
  opacity: .75;
}
li abody.theme-healthcareProfessional .text-primary-dark:hover {
  opacity: 1;
}
body.theme-healthcareProfessional .text-primary-darker {
  color: #4f546b;
}
abody.theme-healthcareProfessional .text-primary-darker:hover,
abody.theme-healthcareProfessional .text-primary-darker:active,
abody.theme-healthcareProfessional .text-primary-darker:focus,
buttonbody.theme-healthcareProfessional .text-primary-darker:hover,
buttonbody.theme-healthcareProfessional .text-primary-darker:active,
buttonbody.theme-healthcareProfessional .text-primary-darker:focus {
  color: #4f546b;
  opacity: .75;
}
li abody.theme-healthcareProfessional .text-primary-darker:hover {
  opacity: 1;
}
body.theme-healthcareProfessional .text-primary-light {
  color: #2ec2ff;
}
abody.theme-healthcareProfessional .text-primary-light:hover,
abody.theme-healthcareProfessional .text-primary-light:active,
abody.theme-healthcareProfessional .text-primary-light:focus,
buttonbody.theme-healthcareProfessional .text-primary-light:hover,
buttonbody.theme-healthcareProfessional .text-primary-light:active,
buttonbody.theme-healthcareProfessional .text-primary-light:focus {
  color: #2ec2ff;
  opacity: .75;
}
li abody.theme-healthcareProfessional .text-primary-light:hover {
  opacity: 1;
}
body.theme-healthcareProfessional .text-primary-lighter {
  color: #61d1ff;
}
abody.theme-healthcareProfessional .text-primary-lighter:hover,
abody.theme-healthcareProfessional .text-primary-lighter:active,
abody.theme-healthcareProfessional .text-primary-lighter:focus,
buttonbody.theme-healthcareProfessional .text-primary-lighter:hover,
buttonbody.theme-healthcareProfessional .text-primary-lighter:active,
buttonbody.theme-healthcareProfessional .text-primary-lighter:focus {
  color: #61d1ff;
  opacity: .75;
}
li abody.theme-healthcareProfessional .text-primary-lighter:hover {
  opacity: 1;
}
body.theme-healthcareProfessional .text-success {
  color: #00ca82;
}
abody.theme-healthcareProfessional .text-success:hover,
abody.theme-healthcareProfessional .text-success:active,
abody.theme-healthcareProfessional .text-success:focus,
buttonbody.theme-healthcareProfessional .text-success:hover,
buttonbody.theme-healthcareProfessional .text-success:active,
buttonbody.theme-healthcareProfessional .text-success:focus {
  color: #00ca82;
  opacity: .75;
}
li abody.theme-healthcareProfessional .text-success:hover {
  opacity: 1;
}
body.theme-healthcareProfessional .text-warning {
  color: #f3b760;
}
abody.theme-healthcareProfessional .text-warning:hover,
abody.theme-healthcareProfessional .text-warning:active,
abody.theme-healthcareProfessional .text-warning:focus,
buttonbody.theme-healthcareProfessional .text-warning:hover,
buttonbody.theme-healthcareProfessional .text-warning:active,
buttonbody.theme-healthcareProfessional .text-warning:focus {
  color: #f3b760;
  opacity: .75;
}
li abody.theme-healthcareProfessional .text-warning:hover {
  opacity: 1;
}
body.theme-healthcareProfessional .text-info {
  color: #70b9eb;
}
abody.theme-healthcareProfessional .text-info:hover,
abody.theme-healthcareProfessional .text-info:active,
abody.theme-healthcareProfessional .text-info:focus,
buttonbody.theme-healthcareProfessional .text-info:hover,
buttonbody.theme-healthcareProfessional .text-info:active,
buttonbody.theme-healthcareProfessional .text-info:focus {
  color: #70b9eb;
  opacity: .75;
}
li abody.theme-healthcareProfessional .text-info:hover {
  opacity: 1;
}
body.theme-healthcareProfessional .text-danger {
  color: #F06792;
}
abody.theme-healthcareProfessional .text-danger:hover,
abody.theme-healthcareProfessional .text-danger:active,
abody.theme-healthcareProfessional .text-danger:focus,
buttonbody.theme-healthcareProfessional .text-danger:hover,
buttonbody.theme-healthcareProfessional .text-danger:active,
buttonbody.theme-healthcareProfessional .text-danger:focus {
  color: #F06792;
  opacity: .75;
}
li abody.theme-healthcareProfessional .text-danger:hover {
  opacity: 1;
}
body.theme-healthcareProfessional .text-success-light {
  color: #e0f5e9;
}
abody.theme-healthcareProfessional .text-success-light:hover,
abody.theme-healthcareProfessional .text-success-light:active,
abody.theme-healthcareProfessional .text-success-light:focus,
buttonbody.theme-healthcareProfessional .text-success-light:hover,
buttonbody.theme-healthcareProfessional .text-success-light:active,
buttonbody.theme-healthcareProfessional .text-success-light:focus {
  color: #e0f5e9;
  opacity: .75;
}
li abody.theme-healthcareProfessional .text-success-light:hover {
  opacity: 1;
}
body.theme-healthcareProfessional .text-warning-light {
  color: #fdf3e5;
}
abody.theme-healthcareProfessional .text-warning-light:hover,
abody.theme-healthcareProfessional .text-warning-light:active,
abody.theme-healthcareProfessional .text-warning-light:focus,
buttonbody.theme-healthcareProfessional .text-warning-light:hover,
buttonbody.theme-healthcareProfessional .text-warning-light:active,
buttonbody.theme-healthcareProfessional .text-warning-light:focus {
  color: #fdf3e5;
  opacity: .75;
}
li abody.theme-healthcareProfessional .text-warning-light:hover {
  opacity: 1;
}
body.theme-healthcareProfessional .text-info-light {
  color: #edf6fd;
}
abody.theme-healthcareProfessional .text-info-light:hover,
abody.theme-healthcareProfessional .text-info-light:active,
abody.theme-healthcareProfessional .text-info-light:focus,
buttonbody.theme-healthcareProfessional .text-info-light:hover,
buttonbody.theme-healthcareProfessional .text-info-light:active,
buttonbody.theme-healthcareProfessional .text-info-light:focus {
  color: #edf6fd;
  opacity: .75;
}
li abody.theme-healthcareProfessional .text-info-light:hover {
  opacity: 1;
}
body.theme-healthcareProfessional .text-danger-light {
  color: #f9eae8;
}
abody.theme-healthcareProfessional .text-danger-light:hover,
abody.theme-healthcareProfessional .text-danger-light:active,
abody.theme-healthcareProfessional .text-danger-light:focus,
buttonbody.theme-healthcareProfessional .text-danger-light:hover,
buttonbody.theme-healthcareProfessional .text-danger-light:active,
buttonbody.theme-healthcareProfessional .text-danger-light:focus {
  color: #f9eae8;
  opacity: .75;
}
li abody.theme-healthcareProfessional .text-danger-light:hover {
  opacity: 1;
}
body.theme-healthcareProfessional .text-black {
  color: #000;
}
abody.theme-healthcareProfessional .text-black:hover,
abody.theme-healthcareProfessional .text-black:active,
abody.theme-healthcareProfessional .text-black:focus,
buttonbody.theme-healthcareProfessional .text-black:hover,
buttonbody.theme-healthcareProfessional .text-black:active,
buttonbody.theme-healthcareProfessional .text-black:focus {
  color: #000;
  opacity: .75;
}
li abody.theme-healthcareProfessional .text-black:hover {
  opacity: 1;
}
body.theme-healthcareProfessional .text-black-op {
  color: rgba(0, 0, 0, 0.5);
}
abody.theme-healthcareProfessional .text-black-op:hover,
abody.theme-healthcareProfessional .text-black-op:active,
abody.theme-healthcareProfessional .text-black-op:focus,
buttonbody.theme-healthcareProfessional .text-black-op:hover,
buttonbody.theme-healthcareProfessional .text-black-op:active,
buttonbody.theme-healthcareProfessional .text-black-op:focus {
  color: rgba(0, 0, 0, 0.5);
  opacity: .75;
}
li abody.theme-healthcareProfessional .text-black-op:hover {
  opacity: 1;
}
body.theme-healthcareProfessional .text-gray {
  color: #e6e6e6;
}
abody.theme-healthcareProfessional .text-gray:hover,
abody.theme-healthcareProfessional .text-gray:active,
abody.theme-healthcareProfessional .text-gray:focus,
buttonbody.theme-healthcareProfessional .text-gray:hover,
buttonbody.theme-healthcareProfessional .text-gray:active,
buttonbody.theme-healthcareProfessional .text-gray:focus {
  color: #e6e6e6;
  opacity: .75;
}
li abody.theme-healthcareProfessional .text-gray:hover {
  opacity: 1;
}
body.theme-healthcareProfessional .text-gray-dark {
  color: #cdcdcd;
}
abody.theme-healthcareProfessional .text-gray-dark:hover,
abody.theme-healthcareProfessional .text-gray-dark:active,
abody.theme-healthcareProfessional .text-gray-dark:focus,
buttonbody.theme-healthcareProfessional .text-gray-dark:hover,
buttonbody.theme-healthcareProfessional .text-gray-dark:active,
buttonbody.theme-healthcareProfessional .text-gray-dark:focus {
  color: #cdcdcd;
  opacity: .75;
}
li abody.theme-healthcareProfessional .text-gray-dark:hover {
  opacity: 1;
}
body.theme-healthcareProfessional .text-gray-darker {
  color: #b3b3b3;
}
abody.theme-healthcareProfessional .text-gray-darker:hover,
abody.theme-healthcareProfessional .text-gray-darker:active,
abody.theme-healthcareProfessional .text-gray-darker:focus,
buttonbody.theme-healthcareProfessional .text-gray-darker:hover,
buttonbody.theme-healthcareProfessional .text-gray-darker:active,
buttonbody.theme-healthcareProfessional .text-gray-darker:focus {
  color: #b3b3b3;
  opacity: .75;
}
li abody.theme-healthcareProfessional .text-gray-darker:hover {
  opacity: 1;
}
body.theme-healthcareProfessional .text-gray-light {
  color: #ebebeb;
}
abody.theme-healthcareProfessional .text-gray-light:hover,
abody.theme-healthcareProfessional .text-gray-light:active,
abody.theme-healthcareProfessional .text-gray-light:focus,
buttonbody.theme-healthcareProfessional .text-gray-light:hover,
buttonbody.theme-healthcareProfessional .text-gray-light:active,
buttonbody.theme-healthcareProfessional .text-gray-light:focus {
  color: #ebebeb;
  opacity: .75;
}
li abody.theme-healthcareProfessional .text-gray-light:hover {
  opacity: 1;
}
body.theme-healthcareProfessional .text-gray-lighter {
  color: #f3f3f3;
}
abody.theme-healthcareProfessional .text-gray-lighter:hover,
abody.theme-healthcareProfessional .text-gray-lighter:active,
abody.theme-healthcareProfessional .text-gray-lighter:focus,
buttonbody.theme-healthcareProfessional .text-gray-lighter:hover,
buttonbody.theme-healthcareProfessional .text-gray-lighter:active,
buttonbody.theme-healthcareProfessional .text-gray-lighter:focus {
  color: #f3f3f3;
  opacity: .75;
}
li abody.theme-healthcareProfessional .text-gray-lighter:hover {
  opacity: 1;
}
body.theme-healthcareProfessional .text-white {
  color: #fff;
}
abody.theme-healthcareProfessional .text-white:hover,
abody.theme-healthcareProfessional .text-white:active,
abody.theme-healthcareProfessional .text-white:focus,
buttonbody.theme-healthcareProfessional .text-white:hover,
buttonbody.theme-healthcareProfessional .text-white:active,
buttonbody.theme-healthcareProfessional .text-white:focus {
  color: #fff;
  opacity: .75;
}
li abody.theme-healthcareProfessional .text-white:hover {
  opacity: 1;
}
body.theme-healthcareProfessional .text-white-op {
  color: rgba(255, 255, 255, 0.85);
}
abody.theme-healthcareProfessional .text-white-op:hover,
abody.theme-healthcareProfessional .text-white-op:active,
abody.theme-healthcareProfessional .text-white-op:focus,
buttonbody.theme-healthcareProfessional .text-white-op:hover,
buttonbody.theme-healthcareProfessional .text-white-op:active,
buttonbody.theme-healthcareProfessional .text-white-op:focus {
  color: rgba(255, 255, 255, 0.85);
  opacity: .75;
}
li abody.theme-healthcareProfessional .text-white-op:hover {
  opacity: 1;
}
body.theme-healthcareProfessional .text-muted {
  color: #cdcdcd;
}
abody.theme-healthcareProfessional .text-muted:hover,
abody.theme-healthcareProfessional .text-muted:active,
abody.theme-healthcareProfessional .text-muted:focus,
buttonbody.theme-healthcareProfessional .text-muted:hover,
buttonbody.theme-healthcareProfessional .text-muted:active,
buttonbody.theme-healthcareProfessional .text-muted:focus {
  color: #cdcdcd;
  opacity: .75;
}
li abody.theme-healthcareProfessional .text-muted:hover {
  opacity: 1;
}
body.theme-healthcareProfessional .text-infusion {
  color: #FFB137;
}
abody.theme-healthcareProfessional .text-infusion:hover,
abody.theme-healthcareProfessional .text-infusion:active,
abody.theme-healthcareProfessional .text-infusion:focus,
buttonbody.theme-healthcareProfessional .text-infusion:hover,
buttonbody.theme-healthcareProfessional .text-infusion:active,
buttonbody.theme-healthcareProfessional .text-infusion:focus {
  color: #FFB137;
  opacity: .75;
}
li abody.theme-healthcareProfessional .text-infusion:hover {
  opacity: 1;
}
body.theme-healthcareProfessional .text-bleed {
  color: #00AAF0;
}
abody.theme-healthcareProfessional .text-bleed:hover,
abody.theme-healthcareProfessional .text-bleed:active,
abody.theme-healthcareProfessional .text-bleed:focus,
buttonbody.theme-healthcareProfessional .text-bleed:hover,
buttonbody.theme-healthcareProfessional .text-bleed:active,
buttonbody.theme-healthcareProfessional .text-bleed:focus {
  color: #00AAF0;
  opacity: .75;
}
li abody.theme-healthcareProfessional .text-bleed:hover {
  opacity: 1;
}
body.theme-healthcareProfessional .bg-primary {
  background-color: #00AAF0;
}
abody.theme-healthcareProfessional .bg-primary:hover,
abody.theme-healthcareProfessional .bg-primary:focus {
  background-color: #0086bd;
}
body.theme-healthcareProfessional .bg-primary-op {
  background-color: rgba(0, 170, 240, 0.75);
}
abody.theme-healthcareProfessional .bg-primary-op:hover,
abody.theme-healthcareProfessional .bg-primary-op:focus {
  background-color: rgba(0, 134, 189, 0.75);
}
body.theme-healthcareProfessional .bg-primary-dark {
  background-color: #898989;
}
abody.theme-healthcareProfessional .bg-primary-dark:hover,
abody.theme-healthcareProfessional .bg-primary-dark:focus {
  background-color: #707070;
}
body.theme-healthcareProfessional .bg-primary-dark-op {
  background-color: rgba(137, 137, 137, 0.83);
}
abody.theme-healthcareProfessional .bg-primary-dark-op:hover,
abody.theme-healthcareProfessional .bg-primary-dark-op:focus {
  background-color: rgba(112, 112, 112, 0.83);
}
body.theme-healthcareProfessional .bg-primary-darker {
  background-color: #4f546b;
}
abody.theme-healthcareProfessional .bg-primary-darker:hover,
abody.theme-healthcareProfessional .bg-primary-darker:focus {
  background-color: #393d4e;
}
body.theme-healthcareProfessional .bg-primary-light {
  background-color: #2ec2ff;
}
abody.theme-healthcareProfessional .bg-primary-light:hover,
abody.theme-healthcareProfessional .bg-primary-light:focus {
  background-color: #00b1fa;
}
body.theme-healthcareProfessional .bg-primary-lighter {
  background-color: #61d1ff;
}
abody.theme-healthcareProfessional .bg-primary-lighter:hover,
abody.theme-healthcareProfessional .bg-primary-lighter:focus {
  background-color: #2ec2ff;
}
body.theme-healthcareProfessional .border-primary {
  border-color: #00AAF0;
}
body.theme-healthcareProfessional .border-primary-op {
  border-color: rgba(0, 170, 240, 0.75);
}
body.theme-healthcareProfessional .border-primary-dark {
  border-color: #898989;
}
body.theme-healthcareProfessional .border-primary-dark-op {
  border-color: rgba(137, 137, 137, 0.83);
}
body.theme-healthcareProfessional .border-primary-darker {
  border-color: #4f546b;
}
body.theme-healthcareProfessional .border-primary-light {
  border-color: #2ec2ff;
}
body.theme-healthcareProfessional .border-primary-lighter {
  border-color: #61d1ff;
}
body.theme-healthcareProfessional .btn-default-primary {
  color: #545454;
  background-color: #f5f5f5;
  border-color: #e9e9e9;
}
body.theme-healthcareProfessional .btn-default-primary:focus,
body.theme-healthcareProfessional .btn-default-primary.focus,
body.theme-healthcareProfessional .btn-default-primary:hover {
  color: #fff;
  background-color: #24bfff;
  border-color: #007fb3;
}
body.theme-healthcareProfessional .btn-default-primary:active,
body.theme-healthcareProfessional .btn-default-primary.active,
.open > .dropdown-togglebody.theme-healthcareProfessional .btn-default-primary {
  color: #fff;
  background-color: #00aaf0;
  border-color: #005b80;
}
body.theme-healthcareProfessional .btn-default-primary:active:hover,
body.theme-healthcareProfessional .btn-default-primary.active:hover,
.open > .dropdown-togglebody.theme-healthcareProfessional .btn-default-primary:hover,
body.theme-healthcareProfessional .btn-default-primary:active:focus,
body.theme-healthcareProfessional .btn-default-primary.active:focus,
.open > .dropdown-togglebody.theme-healthcareProfessional .btn-default-primary:focus,
body.theme-healthcareProfessional .btn-default-primary:active.focus,
body.theme-healthcareProfessional .btn-default-primary.active.focus,
.open > .dropdown-togglebody.theme-healthcareProfessional .btn-default-primary.focus {
  color: #fff;
  background-color: #00aaf0;
  border-color: #005b80;
}
body.theme-healthcareProfessional .btn-default-primary:active,
body.theme-healthcareProfessional .btn-default-primary.active,
.open > .dropdown-togglebody.theme-healthcareProfessional .btn-default-primary {
  background-image: none;
}
body.theme-healthcareProfessional .btn-default-primary.disabled,
body.theme-healthcareProfessional .btn-default-primary[disabled],
fieldset[disabled] body.theme-healthcareProfessional .btn-default-primary,
body.theme-healthcareProfessional .btn-default-primary.disabled:hover,
body.theme-healthcareProfessional .btn-default-primary[disabled]:hover,
fieldset[disabled] body.theme-healthcareProfessional .btn-default-primary:hover,
body.theme-healthcareProfessional .btn-default-primary.disabled:focus,
body.theme-healthcareProfessional .btn-default-primary[disabled]:focus,
fieldset[disabled] body.theme-healthcareProfessional .btn-default-primary:focus,
body.theme-healthcareProfessional .btn-default-primary.disabled.focus,
body.theme-healthcareProfessional .btn-default-primary[disabled].focus,
fieldset[disabled] body.theme-healthcareProfessional .btn-default-primary.focus,
body.theme-healthcareProfessional .btn-default-primary.disabled:active,
body.theme-healthcareProfessional .btn-default-primary[disabled]:active,
fieldset[disabled] body.theme-healthcareProfessional .btn-default-primary:active,
body.theme-healthcareProfessional .btn-default-primary.disabled.active,
body.theme-healthcareProfessional .btn-default-primary[disabled].active,
fieldset[disabled] body.theme-healthcareProfessional .btn-default-primary.active {
  background-color: #f5f5f5;
  border-color: #e9e9e9;
}
body.theme-healthcareProfessional .btn-default-primary .badge {
  color: #f5f5f5;
  background-color: #545454;
}
body.theme-healthcareProfessional .btn-primary {
  color: #fff;
  background-color: #00AAF0;
  border-color: #0086bd;
}
body.theme-healthcareProfessional .btn-primary:focus,
body.theme-healthcareProfessional .btn-primary.focus,
body.theme-healthcareProfessional .btn-primary:hover {
  color: #fff;
  background-color: #008dc7;
  border-color: #005b80;
}
body.theme-healthcareProfessional .btn-primary:active,
body.theme-healthcareProfessional .btn-primary.active,
.open > .dropdown-togglebody.theme-healthcareProfessional .btn-primary {
  color: #fff;
  background-color: #006994;
  border-color: #00364d;
}
body.theme-healthcareProfessional .btn-primary:active:hover,
body.theme-healthcareProfessional .btn-primary.active:hover,
.open > .dropdown-togglebody.theme-healthcareProfessional .btn-primary:hover,
body.theme-healthcareProfessional .btn-primary:active:focus,
body.theme-healthcareProfessional .btn-primary.active:focus,
.open > .dropdown-togglebody.theme-healthcareProfessional .btn-primary:focus,
body.theme-healthcareProfessional .btn-primary:active.focus,
body.theme-healthcareProfessional .btn-primary.active.focus,
.open > .dropdown-togglebody.theme-healthcareProfessional .btn-primary.focus {
  color: #fff;
  background-color: #006994;
  border-color: #00364d;
}
body.theme-healthcareProfessional .btn-primary:active,
body.theme-healthcareProfessional .btn-primary.active,
.open > .dropdown-togglebody.theme-healthcareProfessional .btn-primary {
  background-image: none;
}
body.theme-healthcareProfessional .btn-primary.disabled,
body.theme-healthcareProfessional .btn-primary[disabled],
fieldset[disabled] body.theme-healthcareProfessional .btn-primary,
body.theme-healthcareProfessional .btn-primary.disabled:hover,
body.theme-healthcareProfessional .btn-primary[disabled]:hover,
fieldset[disabled] body.theme-healthcareProfessional .btn-primary:hover,
body.theme-healthcareProfessional .btn-primary.disabled:focus,
body.theme-healthcareProfessional .btn-primary[disabled]:focus,
fieldset[disabled] body.theme-healthcareProfessional .btn-primary:focus,
body.theme-healthcareProfessional .btn-primary.disabled.focus,
body.theme-healthcareProfessional .btn-primary[disabled].focus,
fieldset[disabled] body.theme-healthcareProfessional .btn-primary.focus,
body.theme-healthcareProfessional .btn-primary.disabled:active,
body.theme-healthcareProfessional .btn-primary[disabled]:active,
fieldset[disabled] body.theme-healthcareProfessional .btn-primary:active,
body.theme-healthcareProfessional .btn-primary.disabled.active,
body.theme-healthcareProfessional .btn-primary[disabled].active,
fieldset[disabled] body.theme-healthcareProfessional .btn-primary.active {
  background-color: #00AAF0;
  border-color: #0086bd;
}
body.theme-healthcareProfessional .btn-primary .badge {
  color: #00AAF0;
  background-color: #fff;
}
body.theme-healthcareProfessional .btn-outline {
  color: #646464;
  background-color: #e6e6e6;
  border-color: #0086bd;
}
body.theme-healthcareProfessional .btn-outline:focus,
body.theme-healthcareProfessional .btn-outline.focus,
body.theme-healthcareProfessional .btn-outline:hover {
  color: #646464;
  background-color: #d2d2d2;
  border-color: #005b80;
}
body.theme-healthcareProfessional .btn-outline:active,
body.theme-healthcareProfessional .btn-outline.active,
.open > .dropdown-togglebody.theme-healthcareProfessional .btn-outline {
  color: #646464;
  background-color: #b8b8b8;
  border-color: #00364d;
}
body.theme-healthcareProfessional .btn-outline:active:hover,
body.theme-healthcareProfessional .btn-outline.active:hover,
.open > .dropdown-togglebody.theme-healthcareProfessional .btn-outline:hover,
body.theme-healthcareProfessional .btn-outline:active:focus,
body.theme-healthcareProfessional .btn-outline.active:focus,
.open > .dropdown-togglebody.theme-healthcareProfessional .btn-outline:focus,
body.theme-healthcareProfessional .btn-outline:active.focus,
body.theme-healthcareProfessional .btn-outline.active.focus,
.open > .dropdown-togglebody.theme-healthcareProfessional .btn-outline.focus {
  color: #646464;
  background-color: #b8b8b8;
  border-color: #00364d;
}
body.theme-healthcareProfessional .btn-outline:active,
body.theme-healthcareProfessional .btn-outline.active,
.open > .dropdown-togglebody.theme-healthcareProfessional .btn-outline {
  background-image: none;
}
body.theme-healthcareProfessional .btn-outline.disabled,
body.theme-healthcareProfessional .btn-outline[disabled],
fieldset[disabled] body.theme-healthcareProfessional .btn-outline,
body.theme-healthcareProfessional .btn-outline.disabled:hover,
body.theme-healthcareProfessional .btn-outline[disabled]:hover,
fieldset[disabled] body.theme-healthcareProfessional .btn-outline:hover,
body.theme-healthcareProfessional .btn-outline.disabled:focus,
body.theme-healthcareProfessional .btn-outline[disabled]:focus,
fieldset[disabled] body.theme-healthcareProfessional .btn-outline:focus,
body.theme-healthcareProfessional .btn-outline.disabled.focus,
body.theme-healthcareProfessional .btn-outline[disabled].focus,
fieldset[disabled] body.theme-healthcareProfessional .btn-outline.focus,
body.theme-healthcareProfessional .btn-outline.disabled:active,
body.theme-healthcareProfessional .btn-outline[disabled]:active,
fieldset[disabled] body.theme-healthcareProfessional .btn-outline:active,
body.theme-healthcareProfessional .btn-outline.disabled.active,
body.theme-healthcareProfessional .btn-outline[disabled].active,
fieldset[disabled] body.theme-healthcareProfessional .btn-outline.active {
  background-color: #e6e6e6;
  border-color: #0086bd;
}
body.theme-healthcareProfessional .btn-outline .badge {
  color: #e6e6e6;
  background-color: #646464;
}
body.theme-healthcareProfessional .label-primary {
  background-color: #00AAF0;
}
body.theme-healthcareProfessional .label-primary[href]:hover,
body.theme-healthcareProfessional .label-primary[href]:focus {
  background-color: #0086bd;
}
body.theme-healthcareProfessional .badge-primary {
  background-color: #00AAF0;
}
body.theme-healthcareProfessional .progress-bar-primary {
  background-color: #00AAF0;
}
body.theme-healthcareProfessional .nav-pills > li.active > a,
body.theme-healthcareProfessional .nav-pills > li.active > a:hover,
body.theme-healthcareProfessional .nav-pills > li.active > a:focus {
  background-color: #00AAF0;
}
body.theme-healthcareProfessional .nav-pills > li.active > a > .badge {
  color: #00AAF0;
}
body.theme-healthcareProfessional .pagination > li > a:hover,
body.theme-healthcareProfessional .pagination > li > span:hover,
body.theme-healthcareProfessional .pagination > li > a:focus,
body.theme-healthcareProfessional .pagination > li > span:focus {
  color: #00AAF0;
  -webkit-box-shadow: 0 2px #00AAF0;
  box-shadow: 0 2px #00AAF0;
}
body.theme-healthcareProfessional .pagination > .active > a,
body.theme-healthcareProfessional .pagination > .active > span,
body.theme-healthcareProfessional .pagination > .active > a:hover,
body.theme-healthcareProfessional .pagination > .active > span:hover,
body.theme-healthcareProfessional .pagination > .active > a:focus,
body.theme-healthcareProfessional .pagination > .active > span:focus {
  color: #00AAF0;
  -webkit-box-shadow: 0 2px #00AAF0;
  box-shadow: 0 2px #00AAF0;
}
body.theme-healthcareProfessional .pager li > a:hover,
body.theme-healthcareProfessional .pager li > a:focus {
  color: #00AAF0;
}
body.theme-healthcareProfessional a.list-group-item:hover,
body.theme-healthcareProfessional a.list-group-item:focus {
  color: #00AAF0;
}
body.theme-healthcareProfessional .list-group-item.active,
body.theme-healthcareProfessional .list-group-item.active:hover,
body.theme-healthcareProfessional .list-group-item.active:focus {
  background-color: #00AAF0;
  border-color: #00AAF0;
}
body.theme-healthcareProfessional .list-group-item.active > .badge {
  color: #00AAF0;
}
body.theme-healthcareProfessional .table-header-bg > thead > tr > th,
body.theme-healthcareProfessional .table-header-bg > thead > tr > td {
  background-color: #00AAF0;
  border-bottom-color: #00AAF0;
}
body.theme-healthcareProfessional .panel-primary {
  border-color: #61d1ff;
}
body.theme-healthcareProfessional .panel-primary > .panel-heading {
  color: #00AAF0;
  background-color: #85dbff;
  border-color: #61d1ff;
}
body.theme-healthcareProfessional .panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #61d1ff;
}
body.theme-healthcareProfessional .panel-primary > .panel-heading .badge {
  color: #85dbff;
  background-color: #00AAF0;
}
body.theme-healthcareProfessional .panel-primary > .panel-heading a {
  font-weight: 400;
}
body.theme-healthcareProfessional .panel-primary > .panel-heading a:hover,
body.theme-healthcareProfessional .panel-primary > .panel-heading a:focus {
  color: #0086bd;
}
body.theme-healthcareProfessional .panel-primary > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #61d1ff;
}
body.theme-healthcareProfessional #page-loader:after {
  background-color: #00AAF0;
}
body.theme-healthcareProfessional #header-navbar {
  background-color: #00AAF0;
}
body.theme-healthcareProfessional .header-navbar-transparent.header-navbar-fixed.header-navbar-scroll #header-navbar {
  background-color: #898989;
}
body.theme-healthcareProfessional #page-container,
body.theme-healthcareProfessional #sidebar {
  background-color: #4f546b;
}
body.theme-healthcareProfessional #main-container {
  background-color: #f8f8f8;
}
body.theme-healthcareProfessional .nav-main-header {
  background-color: #4f546b;
}
body.theme-healthcareProfessional .nav-main-header a {
  color: #61d1ff;
}
body.theme-healthcareProfessional .nav-main-header a.link-effect:before {
  background-color: #aee7ff;
}
body.theme-healthcareProfessional .nav-main-header a:hover,
body.theme-healthcareProfessional .nav-main-header a:focus {
  color: #aee7ff;
}
body.theme-healthcareProfessional .nav-main-header a:active {
  color: #61d1ff;
}
@media screen and (min-width: 768px) {
  body.theme-healthcareProfessional .nav-main-header {
    background-color: transparent;
  }
  body.theme-healthcareProfessional .nav-main-header ul {
    background-color: #898989;
  }
}
body.theme-healthcareProfessional .form-material.form-material-primary > .form-control:focus {
  -webkit-box-shadow: 0 2px 0 #00AAF0;
  box-shadow: 0 2px 0 #00AAF0;
}
body.theme-healthcareProfessional .form-material.form-material-primary > .form-control:focus + label {
  color: #00AAF0;
}
body.theme-healthcareProfessional .form-material.form-material-primary > .form-control:focus ~ .input-group-addon {
  color: #00AAF0;
  -webkit-box-shadow: 0 2px 0 #00AAF0;
  box-shadow: 0 2px 0 #00AAF0;
}
body.theme-healthcareProfessional .css-checkbox-primary input:checked + span {
  background-color: #00AAF0;
  border-color: #00AAF0;
}
body.theme-healthcareProfessional .css-radio-primary input:checked + span:after,
body.theme-healthcareProfessional .switch-primary input:checked + span {
  background-color: #00AAF0;
}
body.theme-healthcareProfessional .block > .nav-tabs > li > a:hover {
  color: #00AAF0;
}
body.theme-healthcareProfessional .block > .nav-tabs.nav-tabs-alt > li > a:hover {
  -webkit-box-shadow: 0 2px #00AAF0;
  box-shadow: 0 2px #00AAF0;
}
body.theme-healthcareProfessional .block > .nav-tabs.nav-tabs-alt > li.active > a,
body.theme-healthcareProfessional .block > .nav-tabs.nav-tabs-alt > li.active > a:hover,
body.theme-healthcareProfessional .block > .nav-tabs.nav-tabs-alt > li.active > a:focus {
  -webkit-box-shadow: 0 2px #00AAF0;
  box-shadow: 0 2px #00AAF0;
}
body.theme-healthcareProfessional .ribbon-primary .ribbon-box {
  background-color: #00AAF0;
}
body.theme-healthcareProfessional .ribbon-primary.ribbon-bookmark .ribbon-box:before {
  border-color: #00AAF0;
  border-left-color: transparent;
}
body.theme-healthcareProfessional .ribbon-primary.ribbon-bookmark.ribbon-left .ribbon-box:before {
  border-color: #00AAF0;
  border-right-color: transparent;
}
body.theme-healthcareProfessional .ribbon-primary.ribbon-modern .ribbon-box:before {
  border-color: #00AAF0;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
body.theme-healthcareProfessional .ribbon-primary.ribbon-modern.ribbon-bottom .ribbon-box:before {
  border-color: #00AAF0;
  border-top-color: transparent;
  border-left-color: transparent;
}
body.theme-healthcareProfessional .ribbon-primary.ribbon-modern.ribbon-left .ribbon-box:before {
  border-color: #00AAF0;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
body.theme-healthcareProfessional .ribbon-primary.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box:before {
  border-color: #00AAF0;
  border-top-color: transparent;
  border-right-color: transparent;
}
body.theme-healthcareProfessional .irs-bar,
body.theme-healthcareProfessional .irs-bar-edge,
body.theme-healthcareProfessional .irs-from,
body.theme-healthcareProfessional .irs-to,
body.theme-healthcareProfessional .irs-single,
body.theme-healthcareProfessional .irs-grid-pol {
  background: #00AAF0;
}
body.theme-healthcareProfessional .dropzone:hover {
  border-color: #00AAF0;
}
body.theme-healthcareProfessional .dropzone:hover .dz-message {
  color: #00AAF0;
}
body.theme-healthcareProfessional .datepicker table tr td.active:hover,
body.theme-healthcareProfessional .datepicker table tr td.active:hover:hover,
body.theme-healthcareProfessional .datepicker table tr td.active.disabled:hover,
body.theme-healthcareProfessional .datepicker table tr td.active.disabled:hover:hover,
body.theme-healthcareProfessional .datepicker table tr td.active:focus,
body.theme-healthcareProfessional .datepicker table tr td.active:hover:focus,
body.theme-healthcareProfessional .datepicker table tr td.active.disabled:focus,
body.theme-healthcareProfessional .datepicker table tr td.active.disabled:hover:focus,
body.theme-healthcareProfessional .datepicker table tr td.active:active,
body.theme-healthcareProfessional .datepicker table tr td.active:hover:active,
body.theme-healthcareProfessional .datepicker table tr td.active.disabled:active,
body.theme-healthcareProfessional .datepicker table tr td.active.disabled:hover:active,
body.theme-healthcareProfessional .datepicker table tr td.active.active,
body.theme-healthcareProfessional .datepicker table tr td.active:hover.active,
body.theme-healthcareProfessional .datepicker table tr td.active.disabled.active,
body.theme-healthcareProfessional .datepicker table tr td.active.disabled:hover.active,
body.theme-healthcareProfessional .open .dropdown-toggle.datepicker table tr td.active,
body.theme-healthcareProfessional .open .dropdown-toggle.datepicker table tr td.active:hover,
body.theme-healthcareProfessional .open .dropdown-toggle.datepicker table tr td.active.disabled,
body.theme-healthcareProfessional .open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
body.theme-healthcareProfessional .datepicker table tr td span.active:hover,
body.theme-healthcareProfessional .datepicker table tr td span.active:hover:hover,
body.theme-healthcareProfessional .datepicker table tr td span.active.disabled:hover,
body.theme-healthcareProfessional .datepicker table tr td span.active.disabled:hover:hover,
body.theme-healthcareProfessional .datepicker table tr td span.active:focus,
body.theme-healthcareProfessional .datepicker table tr td span.active:hover:focus,
body.theme-healthcareProfessional .datepicker table tr td span.active.disabled:focus,
body.theme-healthcareProfessional .datepicker table tr td span.active.disabled:hover:focus,
body.theme-healthcareProfessional .datepicker table tr td span.active:active,
body.theme-healthcareProfessional .datepicker table tr td span.active:hover:active,
body.theme-healthcareProfessional .datepicker table tr td span.active.disabled:active,
body.theme-healthcareProfessional .datepicker table tr td span.active.disabled:hover:active,
body.theme-healthcareProfessional .datepicker table tr td span.active.active,
body.theme-healthcareProfessional .datepicker table tr td span.active:hover.active,
body.theme-healthcareProfessional .datepicker table tr td span.active.disabled.active,
body.theme-healthcareProfessional .datepicker table tr td span.active.disabled:hover.active,
body.theme-healthcareProfessional .open .dropdown-toggle.datepicker table tr td span.active,
body.theme-healthcareProfessional .open .dropdown-toggle.datepicker table tr td span.active:hover,
body.theme-healthcareProfessional .open .dropdown-toggle.datepicker table tr td span.active.disabled,
body.theme-healthcareProfessional .open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  background-color: #00AAF0;
  border-color: #00AAF0;
  color: #ffffff;
}
body.theme-healthcareProfessional .datepicker table tr,
body.theme-healthcareProfessional .datepicker table td,
body.theme-healthcareProfessional .datepicker table th {
  text-align: center;
}
body.theme-healthcareProfessional div.tagsinput span.tag {
  background-color: #00AAF0;
}
body.theme-healthcareProfessional .select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #00AAF0;
}
body.theme-healthcareProfessional .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #00AAF0;
}
body.theme-healthcareProfessional .autocomplete-suggestion b {
  color: #00AAF0;
}
.text-healthcareProfessional {
  color: #00AAF0;
}
a.text-healthcareProfessional:hover,
a.text-healthcareProfessional:active,
a.text-healthcareProfessional:focus,
button.text-healthcareProfessional:hover,
button.text-healthcareProfessional:active,
button.text-healthcareProfessional:focus {
  color: #00AAF0;
  opacity: .75;
}
li a.text-healthcareProfessional:hover {
  opacity: 1;
}
.text-healthcareProfessional-dark {
  color: #898989;
}
a.text-healthcareProfessional-dark:hover,
a.text-healthcareProfessional-dark:active,
a.text-healthcareProfessional-dark:focus,
button.text-healthcareProfessional-dark:hover,
button.text-healthcareProfessional-dark:active,
button.text-healthcareProfessional-dark:focus {
  color: #898989;
  opacity: .75;
}
li a.text-healthcareProfessional-dark:hover {
  opacity: 1;
}
.text-healthcareProfessional-darker {
  color: #4f546b;
}
a.text-healthcareProfessional-darker:hover,
a.text-healthcareProfessional-darker:active,
a.text-healthcareProfessional-darker:focus,
button.text-healthcareProfessional-darker:hover,
button.text-healthcareProfessional-darker:active,
button.text-healthcareProfessional-darker:focus {
  color: #4f546b;
  opacity: .75;
}
li a.text-healthcareProfessional-darker:hover {
  opacity: 1;
}
.text-healthcareProfessional-light {
  color: #2ec2ff;
}
a.text-healthcareProfessional-light:hover,
a.text-healthcareProfessional-light:active,
a.text-healthcareProfessional-light:focus,
button.text-healthcareProfessional-light:hover,
button.text-healthcareProfessional-light:active,
button.text-healthcareProfessional-light:focus {
  color: #2ec2ff;
  opacity: .75;
}
li a.text-healthcareProfessional-light:hover {
  opacity: 1;
}
.text-healthcareProfessional-lighter {
  color: #61d1ff;
}
a.text-healthcareProfessional-lighter:hover,
a.text-healthcareProfessional-lighter:active,
a.text-healthcareProfessional-lighter:focus,
button.text-healthcareProfessional-lighter:hover,
button.text-healthcareProfessional-lighter:active,
button.text-healthcareProfessional-lighter:focus {
  color: #61d1ff;
  opacity: .75;
}
li a.text-healthcareProfessional-lighter:hover {
  opacity: 1;
}
.bg-healthcareProfessional {
  background-color: #00AAF0;
}
a.bg-healthcareProfessional:hover,
a.bg-healthcareProfessional:focus {
  background-color: #0086bd;
}
.bg-healthcareProfessional-op {
  background-color: rgba(0, 170, 240, 0.75);
}
a.bg-healthcareProfessional-op:hover,
a.bg-healthcareProfessional-op:focus {
  background-color: rgba(0, 134, 189, 0.75);
}
.bg-healthcareProfessional-dark {
  background-color: #898989;
}
a.bg-healthcareProfessional-dark:hover,
a.bg-healthcareProfessional-dark:focus {
  background-color: #707070;
}
.bg-healthcareProfessional-dark-op {
  background-color: rgba(137, 137, 137, 0.83);
}
a.bg-healthcareProfessional-dark-op:hover,
a.bg-healthcareProfessional-dark-op:focus {
  background-color: rgba(112, 112, 112, 0.83);
}
.bg-healthcareProfessional-darker {
  background-color: #4f546b;
}
a.bg-healthcareProfessional-darker:hover,
a.bg-healthcareProfessional-darker:focus {
  background-color: #393d4e;
}
.bg-healthcareProfessional-light {
  background-color: #2ec2ff;
}
a.bg-healthcareProfessional-light:hover,
a.bg-healthcareProfessional-light:focus {
  background-color: #00b1fa;
}
.bg-healthcareProfessional-lighter {
  background-color: #61d1ff;
}
a.bg-healthcareProfessional-lighter:hover,
a.bg-healthcareProfessional-lighter:focus {
  background-color: #2ec2ff;
}
.border-healthcareProfessional {
  border-color: #00AAF0;
}
.border-healthcareProfessional.block-bordered {
  border-color: #00AAF0;
}
.border-healthcareProfessional-op {
  border-color: rgba(0, 170, 240, 0.75);
}
.border-healthcareProfessional-op.block-bordered {
  border-color: rgba(0, 170, 240, 0.75);
}
.border-healthcareProfessional-dark {
  border-color: #898989;
}
.border-healthcareProfessional-dark.block-bordered {
  border-color: #898989;
}
.border-healthcareProfessional-dark-op {
  border-color: rgba(137, 137, 137, 0.83);
}
.border-healthcareProfessional-dark-op.block-bordered {
  border-color: rgba(137, 137, 137, 0.83);
}
.border-healthcareProfessional-darker {
  border-color: #4f546b;
}
.border-healthcareProfessional-darker.block-bordered {
  border-color: #4f546b;
}
.border-healthcareProfessional-light {
  border-color: #2ec2ff;
}
.border-healthcareProfessional-light.block-bordered {
  border-color: #2ec2ff;
}
.border-healthcareProfessional-lighter {
  border-color: #61d1ff;
}
.border-healthcareProfessional-lighter.block-bordered {
  border-color: #61d1ff;
}
img.bleed-location {
  margin: 0 auto;
  display: block;
  max-width: 100%;
  max-height: 350px;
}
.content.content-boxed > .block-content {
  padding-top: 0;
}
.pain-level {
  color: #ffffff;
}
.pain-level.pain-0 {
  background-color: #44b4a6;
}
a.pain-level.pain-0:hover,
a.pain-level.pain-0:focus {
  background-color: #368f84;
}
.pain-level.pain-1 {
  background-color: #83d0c7;
}
a.pain-level.pain-1:hover,
a.pain-level.pain-1:focus {
  background-color: #5ec2b6;
}
.pain-level.pain-2 {
  background-color: #ffb9d0;
}
a.pain-level.pain-2:hover,
a.pain-level.pain-2:focus {
  background-color: #ff86ae;
}
.pain-level.pain-3 {
  background-color: #ff90b5;
}
a.pain-level.pain-3:hover,
a.pain-level.pain-3:focus {
  background-color: #ff5d93;
}
.pain-level.pain-4 {
  background-color: #ff6c9d;
}
a.pain-level.pain-4:hover,
a.pain-level.pain-4:focus {
  background-color: #ff397b;
}
.pain-level.pain-5 {
  background-color: #ff6b6b;
}
a.pain-level.pain-5:hover,
a.pain-level.pain-5:focus {
  background-color: #ff3838;
}
@media all and (max-width: 768px) {
  .page-header table {
    margin-left: 5px;
    table-layout: fixed;
    width: 100%;
  }
  .page-header table tbody > tr > td {
    padding: 0 10px 10px 0;
  }
}
@media all and (max-width: 767px) {
  .page-header tbody > tr > td {
    display: block;
    width: auto;
    float: left;
    padding: 0 25px 10px 0;
  }
}
