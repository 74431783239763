//
// Color Theme - Patient
// --------------------------------------------------

// Variables and Mixins
@import "../variables.less";
@import "../mixins.less";

// Generate color theme

body.theme-patient{
	.color-theme(@theme-patient-base; @theme-patient-light; @theme-patient-lighter; @theme-patient-dark; @theme-patient-darker; @theme-patient-body);
}

.text-patient {
    .text-emphasis-variant(@theme-patient-base);

    &-dark {
        .text-emphasis-variant(@theme-patient-dark);
    }

    &-darker {
        .text-emphasis-variant(@theme-patient-darker);
    }

    &-light {
        .text-emphasis-variant(@theme-patient-light);
    }

    &-lighter {
        .text-emphasis-variant(@theme-patient-lighter);
    }
}

.bg-patient {
    .bg-variant(@theme-patient-base);

    &-op {
        .bg-variant(fade(@theme-patient-base, 75%));
    }

    &-dark {
        .bg-variant(@theme-patient-dark);
    }

    &-dark-op {
        .bg-variant(fade(@theme-patient-dark, 83%));
    }

    &-darker {
        .bg-variant(@theme-patient-darker);
    }

    &-light {
        .bg-variant(@theme-patient-light);
    }

    &-lighter {
        .bg-variant(@theme-patient-lighter);
    }
}

.border-patient {
    .border-variant(@theme-patient-base);

    &-op {
        .border-variant(fade(@theme-patient-base, 75%));
    }

    &-dark {
        .border-variant(@theme-patient-dark);
    }

    &-dark-op {
        .border-variant(fade(@theme-patient-dark, 83%));
    }

    &-darker {
        .border-variant(@theme-patient-darker);
    }

    &-light {
        .border-variant(@theme-patient-light);
    }

    &-lighter {
        .border-variant(@theme-patient-lighter);
    }
}