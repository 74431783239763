//
// Print
// --------------------------------------------------

@media print {
    #page-container,
    #main-container {
        padding: 0 !important;
    }

    #header-navbar,
    #sidebar,
    #side-overlay,
    .block-options {
        display: none !important;
    }
}