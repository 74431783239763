//
// Color Theme - Flat
// --------------------------------------------------

// Variables and Mixins
@import "../variables.less";
@import "../mixins.less";

// Generate color theme
body.theme-flat{
	.color-theme(@theme-flat-base; @theme-flat-light; @theme-flat-lighter; @theme-flat-dark; @theme-flat-darker; @theme-flat-body);
}