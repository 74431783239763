.cal-row-head [class*="cal-cell"]:first-child,
.cal-row-head [class*="cal-cell"] {
  font-weight: bolder;
  text-align: center;
  border: 0px solid;
  padding: 5px 0;
}
.cal-row-head [class*="cal-cell"] small {
  font-weight: normal;
}
.cal-year-box .row-fluid:hover,
.cal-row-fluid:hover {
  background-color: @rowHover;
}
.cal-month-day {
  height: @rowHeightMonth;
}
[class*="cal-cell"]:hover {
  background-color: @dayHover;
}
.cal-year-box [class*="span"],
.cal-month-box [class*="cal-cell"] {
  min-height: @rowHeightMonth;
  border-right: @borderSizevert @borderStyle @borderColor;
  position: relative;
}
.cal-year-box [class*="span"]{
  min-height: @rowHeightYear;
}
.cal-year-box .row-fluid [class*="span"]:last-child,
.cal-month-box .cal-row-fluid [class*="cal-cell"]:last-child {
  border-right: 0px;
}
.cal-year-box .row-fluid,
.cal-month-box .cal-row-fluid {
  border-bottom: @borderSizehoriz @borderStyle @borderColor;
  margin-left: 0px;
  margin-right: 0px;
}
.cal-year-box .row-fluid:last-child,
.cal-month-box .cal-row-fluid:last-child {
  border-bottom: 0px;
}
.cal-month-box,
.cal-year-box,
.cal-week-box {
  border-top: @borderSizehoriz @borderStyle @borderColor;
  border-bottom: @borderSizehoriz @borderStyle @borderColor;
  border-right: @borderSizevert @borderStyle @borderColor;
  border-left: @borderSizevert @borderStyle @borderColor;
  border-radius: 2px;
}
span[data-cal-date] {
  font-size: 1.2em;
  font-weight: normal;
  opacity: 0.5;
  cursor: pointer;
  transition: all .3s ease-in-out;
  -webkit-transition: all .1s ease-in-out;
  -moz-transition: all .1s ease-in-out;
  -ms-transition: all .1s ease-in-out;
  -o-transition: all .1s ease-in-out;
  margin-top: 15px;
  margin-right: 15px;
}
span[data-cal-date]:hover {
  opacity: 1;
}

.cal-day-outmonth{
  span[data-cal-date]{
      opacity: 0.1;
      cursor: default;
  }
  &:after{
    opacity: 0.1;
  }
}

.cal-day-today {
  background-color: #e8fde7;
}

.cal-day-today span[data-cal-date] {
  color: darkgreen;
}
.cal-month-box .cal-day-today span[data-cal-date] {
  font-size: 1.9em;
}
.cal-day-holiday span[data-cal-date] {
  color: #800080;
}

.cal-day-weekend span[data-cal-date] {
  color: darkred;
}

#cal-week-box {
  border: @borderSize @borderStyle @borderColor;
  border-right: 0px;
  border-radius: 5px 0 0 5px;
  background-color: @rowHover;
  text-align: right;
}

#cal-day-tick {
  border: @borderSize @borderStyle @borderColor;
  border-top: 0px solid;
  border-radius: 0 0 5px 5px;
  background-color: @dayHover;
  text-align: center;
}

#cal-slide-box {
  border-top: 0px solid #8c8c8c;
}

#cal-slide-content {
  padding: 20px;
  color: #ffffff;
  background-image: url("@{calendarImagesPath}/dark_wood.png");
  -webkit-box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
}

#cal-slide-tick {
  background-image: url("@{calendarImagesPath}/tick.png?2");
}
#cal-slide-content:hover {
  background-color: transparent;
}
#cal-slide-content a.event-item {
  color: #ffffff;
  font-weight: normal;
  line-height: 22px;
}
.events-list {
  max-height: @rowHeightMonth  - 53px;
  padding-left: 5px;
}
.cal-column {
  border-left: @borderSize @borderStyle @borderColor;
}
a.cal-event-week {
  text-decoration: none;
  color: #151515;
}
.badge-important {
	background-color:#b94a48;
}
