@color_1: white;
@font_family_1: 'Courier New', Courier, monospace;
@background_color_1: @blue;
@background_color_2: @ygreen;
@background_color_3: @ygreen;
@background_color_4: @purple;
@background_color_5: @pink;


// Responsive Datatables

table.dataTable.dtr-inline.collapsed {
  >tbody {
    >tr {
      >td.child {
        cursor: default !important;
        &:before {
          display: none !important;
        }
      }
      >th.child {
        cursor: default !important;
        &:before {
          display: none !important;
        }
      }
      >td.dataTables_empty {
        cursor: default !important;
        &:before {
          display: none !important;
        }
      }
    }
    >tr[role="row"] {
      >td {
        &:first-child {
          position: relative;
          padding-left: 30px;
          cursor: pointer;
          &:before {
            top: 9px;
            left: 4px;
            height: 14px;
            width: 14px;
            display: block;
            position: absolute;
            color: @color_1;
            border: 2px solid white;
            border-radius: 14px;
            box-shadow: 0 0 3px #444;
            box-sizing: content-box;
            text-align: center;
            text-indent: 0 !important;
            font-family: @font_family_1;
            line-height: 14px;
            content: '+';
            background-color: @background_color_1;
          }
        }
      }
      >th {
        &:first-child {
          position: relative;
          padding-left: 30px;
          cursor: pointer;
          &:before {
            top: 9px;
            left: 4px;
            height: 14px;
            width: 14px;
            display: block;
            position: absolute;
            color: @color_1;
            border: 2px solid white;
            border-radius: 14px;
            box-shadow: 0 0 3px #444;
            box-sizing: content-box;
            text-align: center;
            text-indent: 0 !important;
            font-family: @font_family_1;
            line-height: 14px;
            content: '+';
            background-color: @background_color_1;
          }
        }
      }
    }
    >tr.parent {
      >td {
        &:first-child {
          &:before {
            content: '-';
            background-color: @background_color_2;
          }
        }
      }
      >th {
        &:first-child {
          &:before {
            content: '-';
            background-color: @background_color_2;
          }
        }
      }
    }
  }
}
table.dataTable.dtr-inline.collapsed.compact {
  >tbody {
    >tr {
      >td {
        &:first-child {
          padding-left: 27px;
          &:before {
            top: 5px;
            left: 4px;
            height: 14px;
            width: 14px;
            border-radius: 14px;
            line-height: 14px;
            text-indent: 3px;
          }
        }
      }
      >th {
        &:first-child {
          padding-left: 27px;
          &:before {
            top: 5px;
            left: 4px;
            height: 14px;
            width: 14px;
            border-radius: 14px;
            line-height: 14px;
            text-indent: 3px;
          }
        }
      }
    }
  }
}
table.dataTable.dtr-column {
  >tbody {
    >tr {
      >td.control,
      >th.control {
        position: relative;
        cursor: pointer;
        &:before {
          top: 50%;
          left: 50%;
          height: 16px;
          width: 16px;
          margin-top: -10px;
          margin-left: -10px;
          display: block;
          position: absolute;
          color: @color_1;
          border: 2px solid white;
          border-radius: 14px;
          box-shadow: none;
          box-sizing: content-box;
          text-align: center;
          text-indent: 0 !important;
          font-family: @font_family_1;
          line-height: 16px;
          content: '+';
          background-color: @background_color_1;
        }
      }
    }
    >tr.parent {
      td.control {
        &:before {
          content: '-';
          background-color: @brand-lighter;
        }
      }
      th.control {
        &:before {
          content: '-';
          background-color: @brand-lighter;
        }
      }
    }
  }
  > thead > tr > th.control.sorting_asc {
    &:after{
      display: none;
    }
  }
}
table.dataTable {
  >tbody {
    >tr.child {
      padding: 0.5em 1em;
      &:hover {
        background: transparent !important;
      }
      ul.dtr-details {
        display: inline-block;
        list-style-type: none;
        margin: 0;
        padding: 0;
        >li {
          border-bottom: 1px solid #efefef;
          padding: 0.5em 0;
          &:first-child {
            padding-top: 0;
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
      span.dtr-title {
        display: inline-block;
        min-width: 75px;
        font-weight: bold;
      }
    }
  }
}
div.dtr-modal {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  padding: 10em 1em;
  div.dtr-modal-display {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 50%;
    overflow: auto;
    margin: auto;
    z-index: 102;
    overflow: auto;
    background-color: @background_color_3;
    border: 1px solid black;
    border-radius: 0.5em;
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6);
  }
  div.dtr-modal-content {
    position: relative;
    padding: 1em;
  }
  div.dtr-modal-close {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 22px;
    height: 22px;
    border: 1px solid #eaeaea;
    background-color: @background_color_4;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    z-index: 12;
    &:hover {
      background-color: @background_color_5;
    }
  }
  div.dtr-modal-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 101;
    background: rgba(0, 0, 0, 0.6);
  }
}
div.dtr-bs-modal {
  table.table {
    tr {
      &:first-child {
        td {
          border-top: none;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  div.dataTables_info {
    white-space: normal;
  }
  div.dtr-modal {
    div.dtr-modal-display {
      width: 95%;
    }
  }
}
