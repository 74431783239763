//
// Color Theme - bleed
// --------------------------------------------------

// Variables and Mixins
@import "../variables.less";
@import "../mixins.less";

// Generate color theme

body.theme-bleed{
	.color-theme(@theme-bleed-base; @theme-bleed-light; @theme-bleed-lighter; @theme-bleed-dark; @theme-bleed-darker; @theme-bleed-body);
}

.text-bleed {
    .text-emphasis-variant(@theme-bleed-base);

    &-dark {
        .text-emphasis-variant(@theme-bleed-dark);
    }

    &-darker {
        .text-emphasis-variant(@theme-bleed-darker);
    }

    &-light {
        .text-emphasis-variant(@theme-bleed-light);
    }

    &-lighter {
        .text-emphasis-variant(@theme-bleed-lighter);
    }
}

.bg-bleed {
    .bg-variant(@theme-bleed-base);

    &-op {
        .bg-variant(fade(@theme-bleed-base, 75%));
    }

    &-dark {
        .bg-variant(@theme-bleed-dark);
    }

    &-dark-op {
        .bg-variant(fade(@theme-bleed-dark, 83%));
    }

    &-darker {
        .bg-variant(@theme-bleed-darker);
    }

    &-light {
        .bg-variant(@theme-bleed-light);
    }

    &-lighter {
        .bg-variant(@theme-bleed-lighter);
    }
}

.border-bleed {
    .border-variant(@theme-bleed-base);

    &-op {
        .border-variant(fade(@theme-bleed-base, 75%));
    }

    &-dark {
        .border-variant(@theme-bleed-dark);
    }

    &-dark-op {
        .border-variant(fade(@theme-bleed-dark, 83%));
    }

    &-darker {
        .border-variant(@theme-bleed-darker);
    }

    &-light {
        .border-variant(@theme-bleed-light);
    }

    &-lighter {
        .border-variant(@theme-bleed-lighter);
    }
}