//
// Utilities
// --------------------------------------------------

.cursor-link{
    cursor: pointer;
}

.cursor-default{
    cursor: default;
}

.pin-bottom{
    position: absolute;
    bottom: 0;

    &-left{
        position: absolute;
        left: 20px;
        bottom: 00px;
        span{
            display: inline-block;
        }
    }

    &-middle{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    span{
        display: inline-block;
    }
}
.pin-right{
    position: absolute;
    right: 20px;
}

[data-convertdate]{
    display: none;
}

.no-wrap{
    white-space: nowrap;
}


// Layout
.push-0,
.push-none {
    .push-generate(0px);
}
.pad-0,
.pad-none{
    .pad-generate(0px);   
}
.push-5 {
    .push-generate(5px);
}
.pad-5{
    .pad-generate(5px);   
}
.push-10 {
    .push-generate(10px);
}
.pad-10{
    .pad-generate(10px);   
}
.push-15 {
    .push-generate(15px);
}
.pad-15{
    .pad-generate(15px);   
}
.push-20 {
    .push-generate(20px);
}
.pad-20{
    .pad-generate(20px);   
}
.push-30 {
    .push-generate(30px);
}
.pad-30{
    .pad-generate(30px);   
}
.push-40 {
    .push-generate(40px);
}
.pad-40{
    .pad-generate(40px);   
}
.push-50 {
    .push-generate(50px);
}
.pad-50{
    .pad-generate(50px);   
}
.push-60 {
    .push-generate(60px);
}
.pad-60{
    .pad-generate(60px);   
}
.push-80 {
    .push-generate(80px);
}
.pad-80{
    .pad-generate(80px);   
}
.push-90 {
    .push-generate(90px);
}
.pad-90{
    .pad-generate(90px);   
}
.push-100 {
    .push-generate(100px);
}
.pad-100{
    .pad-generate(100px);   
}
.push-150 {
    .push-generate(150px);
}
.pad-150{
    .pad-generate(150px);   
}
.pulldown {
    position: relative;
    top: 50px;

    @media screen and (min-width: @screen-md-min) {
        top: 150px;
    }
}

.remove-margin {
    margin: 0 !important;

    &-t { margin-top: 0 !important; }
    &-r { margin-right: 0 !important; }
    &-b { margin-bottom: 0 !important; }
    &-l { margin-left: 0 !important; }
}

.remove-padding {
    padding: 0 !important;

    &-t { padding-top: 0 !important; }
    &-r { padding-right: 0 !important; }
    &-b { padding-bottom: 0 !important; }
    &-l { padding-left: 0 !important; }
}

// Height helpers
// Generate min-heights every 5px between 50 and 250
.mheight-generate(50, 250);
.maxheight-generate(50, 250);

// Generate min-heights every 10px between 250 and 500
.mheight-generate(250, 500, 10);
.maxheight-generate(250, 500, 10);

// Generate min-heights every 25px between 250 and 800
.mheight-generate(250, 800, 25);
.maxheight-generate(250, 800, 25);

.maxwidth-85{
    max-width: 85%;
}

.maxwidth-90{
    max-width: 90%;
}

.maxwidth-100{
    max-width: 100%;
}

// Vertical center align
.align-v {
    .vertical-align()!important;

    &&-fwidth {
        width: 100%;
    }
}

// Border
.border {
    border: 1px solid #e9e9e9;

    &-t { border-top: 1px solid #e9e9e9; }
    &-r { border-right: 1px solid #e9e9e9; }
    &-b { border-bottom: 1px solid #e9e9e9; }
    &-l { border-left: 1px solid #e9e9e9; }
}

.border-white-op {
    border: 1px solid rgba(255,255,255,.1);

    &-t { border-top: 1px solid rgba(255,255,255,.1); }
    &-r { border-right: 1px solid rgba(255,255,255,.1); }
    &-b { border-bottom: 1px solid rgba(255,255,255,.1); }
    &-l { border-left: 1px solid rgba(255,255,255,.1); }
}

.border-black-op {
    border: 1px solid rgba(0,0,0,.1);

    &-t { border-top: 1px solid rgba(0,0,0,.1); }
    &-r { border-right: 1px solid rgba(0,0,0,.1); }
    &-b { border-bottom: 1px solid rgba(0,0,0,.1); }
    &-l { border-left: 1px solid rgba(0,0,0,.1); }
}

.round{
  border-radius: 50%!important;
}

.square{
  border-radius: 0!important;
}


// Item
i.item {
    display: inline-block;
    width: 60px;
    height: 60px;
    text-align: center;
    font-size: 28px;
    font-weight: 300;
    line-height: 60px;

    a& {
        &:hover,
        &:focus {
            opacity: .6;
        }
    }

    &.item-circle {
        border-radius: 50%;
    }

    &.item-rounded {
        border-radius: 3px;
    }

    &.item-rounded-big {
        border-radius: 24px;

        &.item-2x {
            border-radius: 35px;
        }

        &.item-3x {
            border-radius: 50px;
        }
    }

    &.item-2x {
        width: 100px;
        height: 100px;
        line-height: 100px;
    }

    &.item-3x {
        width: 150px;
        height: 150px;
        line-height: 150px;
    }
}

// Ribbons
.ribbon {
    position: relative;

    &-box {
        position: absolute;
        top: 10px;
        right: 0;
        padding: 0 15px;
        height: 36px;
        line-height: 36px;
        color: #fff;
        z-index: 500;

        &:before {
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            content: "";
        }
    }

    &.block-rounded{
        .ribbon-box {
            border-top-right-radius: 3px;
        }
    }

    // Styles
    &-bookmark {
        .ribbon-box {
            padding-left: 0;
        }

        .ribbon-box:before {
            top: 0;
            right: 100%;
            border: 18px solid;
            border-left-width: 10px;
        }
    }

    &-modern {
        .ribbon-box {
            top: 0;
        }

        .ribbon-box:before {
            top: 0;
            right: 100%;
            border: 18px solid;
        }
    }

    // Position
    &-left {
        .ribbon-box {
            right: auto;
            left: 0;
        }

        &.block-rounded{
            .ribbon-box {
                border-top-right-radius: 0px;
                border-top-left-radius: 3px;
            }
        }

        &.ribbon-bookmark {
            .ribbon-box {
                padding-left: 15px;
                padding-right: 0;
            }

            .ribbon-box:before {
                right: auto;
                left: 100%;
                border-left-width: 18px;
                border-right-width: 10px;
            }
        }

        &.ribbon-modern {
            .ribbon-box:before {
                right: auto;
                left: 100%;
            }
        }
    }

    &-bottom {
        .ribbon-box {
            top: auto;
            bottom: 10px;
        }

        &.ribbon-modern .ribbon-box {
            bottom: 0;
        }

        &.block-rounded{
            .ribbon-box {
                .border-top-radius(0);
            }

            &.ribbon-left{
                .ribbon-box {
                    border-bottom-left-radius: 3px;
                }
            }
            &.ribbon-right{
                .ribbon-box {
                    border-bottom-right-radius: 3px;
                }
            }
        }


    }

    // Colors
    &-primary {
        .ribbon-variation(@brand-primary);
    }

    &-success {
        .ribbon-variation(@brand-success);
    }

    &-info {
        .ribbon-variation(@brand-info);
    }

    &-warning {
        .ribbon-variation(@brand-warning);
    }

    &-danger {
        .ribbon-variation(@brand-danger);
    }

    &-tenant {
        .ribbon-variation(@brand-tenant);
    }

    &-treatmentCenter {
        .ribbon-variation(@brand-treatmentCenter);
    }

    &-hcp {
        .ribbon-variation(@brand-hcp);
    }

    &-patient {
        .ribbon-variation(@brand-patient);
    }

    &-user {
        .ribbon-variation(@brand-user);
    }

    &-alert {
        .ribbon-variation(@brand-alert);
    }

    &-bleed {
        .ribbon-variation(@brand-bleed);
    }

    &-infusion {
        .ribbon-variation(@brand-infusion);
    }

    &-crystal {
        .ribbon-box {
            background-color: rgba(255,255,255,.35);
        }

        &.ribbon-bookmark {
            .ribbon-box:before {
                border-color: rgba(255,255,255,.35);
                border-left-color: transparent;
            }

            &.ribbon-left .ribbon-box:before {
                border-color: rgba(255,255,255,.35);
                border-right-color: transparent;
            }
        }

        &.ribbon-modern {
            .ribbon-box:before {
                border-color: rgba(255,255,255,.35);
                border-left-color: transparent;
                border-bottom-color: transparent;
            }

            &.ribbon-bottom .ribbon-box:before {
                border-color: rgba(255,255,255,.35);
                border-top-color: transparent;
                border-left-color: transparent;
            }

            &.ribbon-left .ribbon-box:before {
                border-color: rgba(255,255,255,.35);
                border-right-color: transparent;
                border-bottom-color: transparent;
            }

            &.ribbon-left.ribbon-bottom .ribbon-box:before {
                border-color: rgba(255,255,255,.35);
                border-top-color: transparent;
                border-right-color: transparent;
            }
        }
    }
}

// Helpers
.overflow-hidden {
    overflow: hidden;
}

.overflow-visible {
    overflow: visible;
}

.overflow-y-auto {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.visibility-hidden {
    visibility: hidden;
}

.opacity-0{
    opacity: 0;
}

.opacity-25{
    opacity: 0.25;
}

.opacity-50{
    opacity: 0.50;
}

.opacity-75{
    opacity: 0.75;
}

.opacity-100{
    opacity: 1;
}

.hover-opacity-0{
    &:hover {
        opacity: 0;
    }
}

.hover-opacity-25{
    &:hover {
        opacity: 0.25;
    }
}

.hover-opacity-50{
    &:hover {
        opacity: 0.50;
    }
}

.hover-opacity-75{
    &:hover {
        opacity: 0.75;
    }
}

.hover-opacity-100{
    &:hover {
        opacity: 1;
    }
}


// IE9
.visible-ie9 {
    display: none;
}

.ie9 {
    .hidden-ie9 {
        display: none !important;
    }

    .visible-ie9 {
        display: block;
    }

    .visible-ie9-ib {
        display: inline-block;
    }
}