//
// Page Styles: Zero Bleeds HCP Dashboard
// --------------------------------------------------

.hcp-widgets{
	html:lang(pl) &{
		.block{
			min-height: 300px;
		}
		.block-header{
			min-height: 72px;
		}
	}
}

.table-button{
	position: absolute;
	right: 45px;
	margin-right: 15px;
	top: 35px;
	z-index: 100;
}

.table-button-searching{
	right: 225px;
	top: 33px;
}

#markAlertsAsReadBtn{
	.table-button();
	#alerts-header.searching &{
		.table-button-searching();
	}
}

#patientList-header .downloadBtn{
	 margin-top: -22px;
	.table-button();
}

#patientList-header.searching .downloadBtn{
	.table-button-searching();
}
