@rowHeightMonth: 60px;
@rowHeightYear: 60px;

@calMaxWidth: 100%;

// MONTH
@rowHover:        transparent;
@dayHover:         transparent;
@borderColor:      transparent;
@borderSize:      0px;
@borderSizehoriz: 0px;
@borderSizevert:  0px;
@borderStyle:     none;

.event-index{
  margin: 0;
  padding: 0;
  p{
    font-size: 12px;
  }
  
}

.cal-row-head,
.cal-month-box{
  max-width: @calMaxWidth;
  margin: 0 auto;
}

.cal-row-head [class*="cal-cell"]:first-child,
.cal-row-head [class*="cal-cell"] {
  font-weight: bolder;
  text-align: center;
  border: 0px solid;
  padding: 0 0 5px 0;
}
.cal-row-head [class*="cal-cell"] small {
  font-weight: normal;
}
.cal-year-box .row:hover,
.cal-row-fluid:hover {
  background-color: @rowHover;
}
.cal-month-day {
  height: @rowHeightMonth;
  margin: 2px;
}

[class*="cal-cell"]:hover,
.cell-focus,
[class*="cal-cell"] .drop-active,
.cal-cell.drop-active,
.cal-week-box .cal-cell1.drop-active,
.cal-day-hour-part.drop-active {
  background-color: @dayHover;
}
.cal-year-box [class*="span"],
.cal-month-box [class*="cal-cell"] {
  min-height: @rowHeightMonth;
  border-right: @borderSizevert @borderStyle @borderColor;
  position: relative;
}
.cal-year-box [class*="span"]{
  min-height: @rowHeightYear;
}
.cal-year-box .row [class*="col-"]:last-child,
.cal-month-box .cal-row [class*="cal-cell"]:last-child {
  border-right: 0px;
}
.cal-year-box .row,
.cal-month-box .cal-row-fluid {
  border-bottom: @borderSizehoriz @borderStyle @borderColor;
  margin-left: 0px;
  margin-right: 0px;
}
.cal-year-box .row:last-child,
.cal-month-box .cal-row-fluid:last-child {
  border-bottom: 0px;
}
.cal-month-box,
.cal-year-box,
.cal-week-box {
  border-top: @borderSizehoriz @borderStyle @borderColor;
  border-bottom: @borderSizehoriz @borderStyle @borderColor;
  border-right: @borderSizevert @borderStyle @borderColor;
  border-left: @borderSizevert @borderStyle @borderColor;
  border-radius: 2px;
}
.cal-month-box {
  border-right: 0px;
  border-bottom: 0px;
}
span[data-cal-date] {
  font-size: 15px;
  font-weight: normal;
  opacity: 1;
  transition: all .3s ease-in-out;
  text-align: center;
  display: block;
  position: relative;
  margin-top: 0;
  margin-right: 0px;
  line-height: @rowHeightMonth - 5px;
  height: @rowHeightMonth - 2px;
  margin: 2px;

}
span[data-cal-date]:hover {
  opacity: 1;
}

.cal-day-outmonth span[data-cal-date] {
  opacity: 0.1;
  cursor: default;
}

.cal-day-today {
  background-color: inherit;
}

.cal-day-today span[data-cal-date] {
  color: inherit;
  font-weight: 700;
}
.cal-month-box .cal-day-today span[data-cal-date] {
  font-size: 15px;
}
.cal-day-holiday span[data-cal-date] {
  color: #800080;
}

.cal-day-weekend span[data-cal-date] {
  color: @brand-dark;
}

#cal-week-box {
  border: @borderSize @borderStyle @borderColor;
  border-right: 0px;
  border-radius: 5px 0 0 5px;
  background-color: @rowHover;
  text-align: right;
}

.cal-week-box .cal-row-head {
  border-bottom: 1px solid #e1e1e1;
}

.cal-day-tick {
  border: @borderSize @borderStyle @borderColor;
  border-top: 0px solid;
  border-radius: 0 0 5px 5px;
  background-color: @dayHover;
  text-align: center;

  .fa {
    display: none;
  }

}

.cal-slide-box {
  border-top: 0px solid #8c8c8c;
  display: none;
}

.cal-slide-content {
  padding: 20px;
  color: #ffffff;
  background-color: #555555;
  -webkit-box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
}

.cal-slide-content a.event-item {
  color: #ffffff;
  font-weight: normal;
}

a.event-item-edit, a.event-item-delete {
  padding-left: 5px;
}

.cal-year-box .cal-slide-content a.event-item,
.cal-year-box a.event-item-edit,
.cal-year-box a.event-item-delete {
  position: relative;
  top: -3px;
}

.events-list {
  max-height: @rowHeightMonth  - 53px;
  padding-left: 5px;
}
.cal-column {
  border-left: @borderSize @borderStyle @borderColor;
}
a.cal-event-week {
  text-decoration: none;
  color: @brand-dark;
}
.badge-important {
	background-color:#b94a48;
}

.pointer {
  cursor:pointer;
}

.cal-year-box:last-child {
  border-bottom: 0px;
}

.cal-context {
  width: 100%;
}

.cal-month-day {
      &.has-bleeds {
        &:after {
          content: '';
          display: block;
          height: 3px;
          width: 89%;
          margin: 0 auto;
          background: @brand-hcp;
          margin-top: -6px;
          position: relative;
        }
      }

      &.has-infusions {
        span {
          color: #fff!important;
          background: @brand-infusion;
          border-width: 0px;
        }
      }

      &.has-logged-infusions {
        span {
          border: 3px solid @ygreen;
          line-height: 52px;
        }        
      }

      &.has-planned-infusions {
        span {
          color: #fff!important;
          background: @ygreen;
        }        
      }

      &.has-missed-infusions {
        span {
          border: 2px solid @brand-alert;
        }
      }

      .event-calendar-bold &{
        &.has-infusions.has-bleeds {
          span{
            .gradient(linear; @red 50%, @brand-infusion 50%; 60deg);
          }
        }
      
        &.has-bleeds {
          &:after{
            display: none;
          }
          span {
            color: #fff!important;
            background: @red;
          }
        }
      }
    }

@media (max-width: 991px) {

  .cal-year-box [class*="span"]:nth-child(2) {
    border-right: 0px;
  }

  .cal-year-box [class*="span"]:nth-child(1), .cal-year-box [class*="span"]:nth-child(2) {
    border-bottom: 1px solid #e1e1e1;
  }

}
