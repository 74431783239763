//
// Color Theme - Healthcare Professional / HCP
// --------------------------------------------------

// Variables and Mixins
@import "../variables.less";
@import "../mixins.less";

// Generate color theme

body.theme-healthcareProfessional{
	.color-theme(@theme-healthcareProfessional-base; @theme-healthcareProfessional-light; @theme-healthcareProfessional-lighter; @theme-healthcareProfessional-dark; @theme-healthcareProfessional-darker; @theme-healthcareProfessional-body);
}

.text-healthcareProfessional {
    .text-emphasis-variant(@theme-healthcareProfessional-base);

    &-dark {
        .text-emphasis-variant(@theme-healthcareProfessional-dark);
    }

    &-darker {
        .text-emphasis-variant(@theme-healthcareProfessional-darker);
    }

    &-light {
        .text-emphasis-variant(@theme-healthcareProfessional-light);
    }

    &-lighter {
        .text-emphasis-variant(@theme-healthcareProfessional-lighter);
    }
}

.bg-healthcareProfessional {
    .bg-variant(@theme-healthcareProfessional-base);

    &-op {
        .bg-variant(fade(@theme-healthcareProfessional-base, 75%));
    }

    &-dark {
        .bg-variant(@theme-healthcareProfessional-dark);
    }

    &-dark-op {
        .bg-variant(fade(@theme-healthcareProfessional-dark, 83%));
    }

    &-darker {
        .bg-variant(@theme-healthcareProfessional-darker);
    }

    &-light {
        .bg-variant(@theme-healthcareProfessional-light);
    }

    &-lighter {
        .bg-variant(@theme-healthcareProfessional-lighter);
    }
}


.border-healthcareProfessional {
    .border-variant(@theme-healthcareProfessional-base);

    &-op {
        .border-variant(fade(@theme-healthcareProfessional-base, 75%));
    }

    &-dark {
        .border-variant(@theme-healthcareProfessional-dark);
    }

    &-dark-op {
        .border-variant(fade(@theme-healthcareProfessional-dark, 83%));
    }

    &-darker {
        .border-variant(@theme-healthcareProfessional-darker);
    }

    &-light {
        .border-variant(@theme-healthcareProfessional-light);
    }

    &-lighter {
        .border-variant(@theme-healthcareProfessional-lighter);
    }
}

img.bleed-location{
    margin: 0 auto;
    display: block;
    max-width: 100%;
    max-height: 350px;
}

.content.content-boxed > .block-content{
    padding-top: 0;
}


// Color Themes
.pain-level{
    color: @white;
    &.pain-0{
        .bg-variant(@theme-flat-base);
    }
    &.pain-1{
        .bg-variant(@theme-flat-light);
    }
    &.pain-2{
        .bg-variant(@theme-smooth-lighter);
    }
    &.pain-3{
        .bg-variant(@theme-smooth-light);
    }
    &.pain-4{
        .bg-variant(@theme-smooth-base);
    }
    &.pain-5{
        .bg-variant(@theme-city-base);
    }
}


// Responsive handling of the patient details header
.page-header{

    @media all and (max-width: @screen-sm-min) {

        table{
            margin-left: 5px;
            // margin-top: -20px;
            table-layout: fixed;
            width: 100%;
            tbody > tr > td{
                padding: 0 10px 10px 0;
            }
        }
    }

     @media all and (max-width: @screen-xs-max) {
        tbody > tr > td{
            display: block;
            width: auto;
            float: left;
            padding: 0 25px 10px 0;
        }
     }
}
