//
// Color Theme - Treatment Center / HTC
// --------------------------------------------------

// Variables and Mixins
@import "../variables.less";
@import "../mixins.less";

// Generate color theme

body.theme-treatmentCenter{
	.color-theme(@theme-treatmentCenter-base; @theme-treatmentCenter-light; @theme-treatmentCenter-lighter; @theme-treatmentCenter-dark; @theme-treatmentCenter-darker; @theme-treatmentCenter-body);
}

.text-treatmentCenter {
    .text-emphasis-variant(@theme-treatmentCenter-base);

    &-dark {
        .text-emphasis-variant(@theme-treatmentCenter-dark);
    }

    &-darker {
        .text-emphasis-variant(@theme-treatmentCenter-darker);
    }

    &-light {
        .text-emphasis-variant(@theme-treatmentCenter-light);
    }

    &-lighter {
        .text-emphasis-variant(@theme-treatmentCenter-lighter);
    }
}

.bg-treatmentCenter {
    .bg-variant(@theme-treatmentCenter-base);

    &-op {
        .bg-variant(fade(@theme-treatmentCenter-base, 75%));
    }

    &-dark {
        .bg-variant(@theme-treatmentCenter-dark);
    }

    &-dark-op {
        .bg-variant(fade(@theme-treatmentCenter-dark, 83%));
    }

    &-darker {
        .bg-variant(@theme-treatmentCenter-darker);
    }

    &-light {
        .bg-variant(@theme-treatmentCenter-light);
    }

    &-lighter {
        .bg-variant(@theme-treatmentCenter-lighter);
    }
}

.border-treatmentCenter {
    .border-variant(@theme-treatmentCenter-base);

    &-op {
        .border-variant(fade(@theme-treatmentCenter-base, 75%));
    }

    &-dark {
        .border-variant(@theme-treatmentCenter-dark);
    }

    &-dark-op {
        .border-variant(fade(@theme-treatmentCenter-dark, 83%));
    }

    &-darker {
        .border-variant(@theme-treatmentCenter-darker);
    }

    &-light {
        .border-variant(@theme-treatmentCenter-light);
    }

    &-lighter {
        .border-variant(@theme-treatmentCenter-lighter);
    }
}