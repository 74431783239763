body.theme-user body {
  background-color: #f8f8f8;
}
body.theme-user a {
  color: #00ca82;
}
body.theme-user a.link-effect:before {
  background-color: #007e51;
}
body.theme-user a:hover,
body.theme-user a:focus {
  color: #007e51;
}
body.theme-user a:active {
  color: #00ca82;
}
body.theme-user .text-primary {
  color: #00ca82;
}
abody.theme-user .text-primary:hover,
abody.theme-user .text-primary:active,
abody.theme-user .text-primary:focus,
buttonbody.theme-user .text-primary:hover,
buttonbody.theme-user .text-primary:active,
buttonbody.theme-user .text-primary:focus {
  color: #00ca82;
  opacity: .75;
}
li abody.theme-user .text-primary:hover {
  opacity: 1;
}
body.theme-user .text-primary-dark {
  color: #4f546b;
}
abody.theme-user .text-primary-dark:hover,
abody.theme-user .text-primary-dark:active,
abody.theme-user .text-primary-dark:focus,
buttonbody.theme-user .text-primary-dark:hover,
buttonbody.theme-user .text-primary-dark:active,
buttonbody.theme-user .text-primary-dark:focus {
  color: #4f546b;
  opacity: .75;
}
li abody.theme-user .text-primary-dark:hover {
  opacity: 1;
}
body.theme-user .text-primary-darker {
  color: #353847;
}
abody.theme-user .text-primary-darker:hover,
abody.theme-user .text-primary-darker:active,
abody.theme-user .text-primary-darker:focus,
buttonbody.theme-user .text-primary-darker:hover,
buttonbody.theme-user .text-primary-darker:active,
buttonbody.theme-user .text-primary-darker:focus {
  color: #353847;
  opacity: .75;
}
li abody.theme-user .text-primary-darker:hover {
  opacity: 1;
}
body.theme-user .text-primary-light {
  color: #08ffa7;
}
abody.theme-user .text-primary-light:hover,
abody.theme-user .text-primary-light:active,
abody.theme-user .text-primary-light:focus,
buttonbody.theme-user .text-primary-light:hover,
buttonbody.theme-user .text-primary-light:active,
buttonbody.theme-user .text-primary-light:focus {
  color: #08ffa7;
  opacity: .75;
}
li abody.theme-user .text-primary-light:hover {
  opacity: 1;
}
body.theme-user .text-primary-lighter {
  color: #3bffb9;
}
abody.theme-user .text-primary-lighter:hover,
abody.theme-user .text-primary-lighter:active,
abody.theme-user .text-primary-lighter:focus,
buttonbody.theme-user .text-primary-lighter:hover,
buttonbody.theme-user .text-primary-lighter:active,
buttonbody.theme-user .text-primary-lighter:focus {
  color: #3bffb9;
  opacity: .75;
}
li abody.theme-user .text-primary-lighter:hover {
  opacity: 1;
}
body.theme-user .text-success {
  color: #00ca82;
}
abody.theme-user .text-success:hover,
abody.theme-user .text-success:active,
abody.theme-user .text-success:focus,
buttonbody.theme-user .text-success:hover,
buttonbody.theme-user .text-success:active,
buttonbody.theme-user .text-success:focus {
  color: #00ca82;
  opacity: .75;
}
li abody.theme-user .text-success:hover {
  opacity: 1;
}
body.theme-user .text-warning {
  color: #f3b760;
}
abody.theme-user .text-warning:hover,
abody.theme-user .text-warning:active,
abody.theme-user .text-warning:focus,
buttonbody.theme-user .text-warning:hover,
buttonbody.theme-user .text-warning:active,
buttonbody.theme-user .text-warning:focus {
  color: #f3b760;
  opacity: .75;
}
li abody.theme-user .text-warning:hover {
  opacity: 1;
}
body.theme-user .text-info {
  color: #70b9eb;
}
abody.theme-user .text-info:hover,
abody.theme-user .text-info:active,
abody.theme-user .text-info:focus,
buttonbody.theme-user .text-info:hover,
buttonbody.theme-user .text-info:active,
buttonbody.theme-user .text-info:focus {
  color: #70b9eb;
  opacity: .75;
}
li abody.theme-user .text-info:hover {
  opacity: 1;
}
body.theme-user .text-danger {
  color: #F06792;
}
abody.theme-user .text-danger:hover,
abody.theme-user .text-danger:active,
abody.theme-user .text-danger:focus,
buttonbody.theme-user .text-danger:hover,
buttonbody.theme-user .text-danger:active,
buttonbody.theme-user .text-danger:focus {
  color: #F06792;
  opacity: .75;
}
li abody.theme-user .text-danger:hover {
  opacity: 1;
}
body.theme-user .text-success-light {
  color: #e0f5e9;
}
abody.theme-user .text-success-light:hover,
abody.theme-user .text-success-light:active,
abody.theme-user .text-success-light:focus,
buttonbody.theme-user .text-success-light:hover,
buttonbody.theme-user .text-success-light:active,
buttonbody.theme-user .text-success-light:focus {
  color: #e0f5e9;
  opacity: .75;
}
li abody.theme-user .text-success-light:hover {
  opacity: 1;
}
body.theme-user .text-warning-light {
  color: #fdf3e5;
}
abody.theme-user .text-warning-light:hover,
abody.theme-user .text-warning-light:active,
abody.theme-user .text-warning-light:focus,
buttonbody.theme-user .text-warning-light:hover,
buttonbody.theme-user .text-warning-light:active,
buttonbody.theme-user .text-warning-light:focus {
  color: #fdf3e5;
  opacity: .75;
}
li abody.theme-user .text-warning-light:hover {
  opacity: 1;
}
body.theme-user .text-info-light {
  color: #edf6fd;
}
abody.theme-user .text-info-light:hover,
abody.theme-user .text-info-light:active,
abody.theme-user .text-info-light:focus,
buttonbody.theme-user .text-info-light:hover,
buttonbody.theme-user .text-info-light:active,
buttonbody.theme-user .text-info-light:focus {
  color: #edf6fd;
  opacity: .75;
}
li abody.theme-user .text-info-light:hover {
  opacity: 1;
}
body.theme-user .text-danger-light {
  color: #f9eae8;
}
abody.theme-user .text-danger-light:hover,
abody.theme-user .text-danger-light:active,
abody.theme-user .text-danger-light:focus,
buttonbody.theme-user .text-danger-light:hover,
buttonbody.theme-user .text-danger-light:active,
buttonbody.theme-user .text-danger-light:focus {
  color: #f9eae8;
  opacity: .75;
}
li abody.theme-user .text-danger-light:hover {
  opacity: 1;
}
body.theme-user .text-black {
  color: #000;
}
abody.theme-user .text-black:hover,
abody.theme-user .text-black:active,
abody.theme-user .text-black:focus,
buttonbody.theme-user .text-black:hover,
buttonbody.theme-user .text-black:active,
buttonbody.theme-user .text-black:focus {
  color: #000;
  opacity: .75;
}
li abody.theme-user .text-black:hover {
  opacity: 1;
}
body.theme-user .text-black-op {
  color: rgba(0, 0, 0, 0.5);
}
abody.theme-user .text-black-op:hover,
abody.theme-user .text-black-op:active,
abody.theme-user .text-black-op:focus,
buttonbody.theme-user .text-black-op:hover,
buttonbody.theme-user .text-black-op:active,
buttonbody.theme-user .text-black-op:focus {
  color: rgba(0, 0, 0, 0.5);
  opacity: .75;
}
li abody.theme-user .text-black-op:hover {
  opacity: 1;
}
body.theme-user .text-gray {
  color: #e6e6e6;
}
abody.theme-user .text-gray:hover,
abody.theme-user .text-gray:active,
abody.theme-user .text-gray:focus,
buttonbody.theme-user .text-gray:hover,
buttonbody.theme-user .text-gray:active,
buttonbody.theme-user .text-gray:focus {
  color: #e6e6e6;
  opacity: .75;
}
li abody.theme-user .text-gray:hover {
  opacity: 1;
}
body.theme-user .text-gray-dark {
  color: #cdcdcd;
}
abody.theme-user .text-gray-dark:hover,
abody.theme-user .text-gray-dark:active,
abody.theme-user .text-gray-dark:focus,
buttonbody.theme-user .text-gray-dark:hover,
buttonbody.theme-user .text-gray-dark:active,
buttonbody.theme-user .text-gray-dark:focus {
  color: #cdcdcd;
  opacity: .75;
}
li abody.theme-user .text-gray-dark:hover {
  opacity: 1;
}
body.theme-user .text-gray-darker {
  color: #b3b3b3;
}
abody.theme-user .text-gray-darker:hover,
abody.theme-user .text-gray-darker:active,
abody.theme-user .text-gray-darker:focus,
buttonbody.theme-user .text-gray-darker:hover,
buttonbody.theme-user .text-gray-darker:active,
buttonbody.theme-user .text-gray-darker:focus {
  color: #b3b3b3;
  opacity: .75;
}
li abody.theme-user .text-gray-darker:hover {
  opacity: 1;
}
body.theme-user .text-gray-light {
  color: #ebebeb;
}
abody.theme-user .text-gray-light:hover,
abody.theme-user .text-gray-light:active,
abody.theme-user .text-gray-light:focus,
buttonbody.theme-user .text-gray-light:hover,
buttonbody.theme-user .text-gray-light:active,
buttonbody.theme-user .text-gray-light:focus {
  color: #ebebeb;
  opacity: .75;
}
li abody.theme-user .text-gray-light:hover {
  opacity: 1;
}
body.theme-user .text-gray-lighter {
  color: #f3f3f3;
}
abody.theme-user .text-gray-lighter:hover,
abody.theme-user .text-gray-lighter:active,
abody.theme-user .text-gray-lighter:focus,
buttonbody.theme-user .text-gray-lighter:hover,
buttonbody.theme-user .text-gray-lighter:active,
buttonbody.theme-user .text-gray-lighter:focus {
  color: #f3f3f3;
  opacity: .75;
}
li abody.theme-user .text-gray-lighter:hover {
  opacity: 1;
}
body.theme-user .text-white {
  color: #fff;
}
abody.theme-user .text-white:hover,
abody.theme-user .text-white:active,
abody.theme-user .text-white:focus,
buttonbody.theme-user .text-white:hover,
buttonbody.theme-user .text-white:active,
buttonbody.theme-user .text-white:focus {
  color: #fff;
  opacity: .75;
}
li abody.theme-user .text-white:hover {
  opacity: 1;
}
body.theme-user .text-white-op {
  color: rgba(255, 255, 255, 0.85);
}
abody.theme-user .text-white-op:hover,
abody.theme-user .text-white-op:active,
abody.theme-user .text-white-op:focus,
buttonbody.theme-user .text-white-op:hover,
buttonbody.theme-user .text-white-op:active,
buttonbody.theme-user .text-white-op:focus {
  color: rgba(255, 255, 255, 0.85);
  opacity: .75;
}
li abody.theme-user .text-white-op:hover {
  opacity: 1;
}
body.theme-user .text-muted {
  color: #cdcdcd;
}
abody.theme-user .text-muted:hover,
abody.theme-user .text-muted:active,
abody.theme-user .text-muted:focus,
buttonbody.theme-user .text-muted:hover,
buttonbody.theme-user .text-muted:active,
buttonbody.theme-user .text-muted:focus {
  color: #cdcdcd;
  opacity: .75;
}
li abody.theme-user .text-muted:hover {
  opacity: 1;
}
body.theme-user .text-infusion {
  color: #FFB137;
}
abody.theme-user .text-infusion:hover,
abody.theme-user .text-infusion:active,
abody.theme-user .text-infusion:focus,
buttonbody.theme-user .text-infusion:hover,
buttonbody.theme-user .text-infusion:active,
buttonbody.theme-user .text-infusion:focus {
  color: #FFB137;
  opacity: .75;
}
li abody.theme-user .text-infusion:hover {
  opacity: 1;
}
body.theme-user .text-bleed {
  color: #00AAF0;
}
abody.theme-user .text-bleed:hover,
abody.theme-user .text-bleed:active,
abody.theme-user .text-bleed:focus,
buttonbody.theme-user .text-bleed:hover,
buttonbody.theme-user .text-bleed:active,
buttonbody.theme-user .text-bleed:focus {
  color: #00AAF0;
  opacity: .75;
}
li abody.theme-user .text-bleed:hover {
  opacity: 1;
}
body.theme-user .bg-primary {
  background-color: #00ca82;
}
abody.theme-user .bg-primary:hover,
abody.theme-user .bg-primary:focus {
  background-color: #009761;
}
body.theme-user .bg-primary-op {
  background-color: rgba(0, 202, 130, 0.75);
}
abody.theme-user .bg-primary-op:hover,
abody.theme-user .bg-primary-op:focus {
  background-color: rgba(0, 151, 97, 0.75);
}
body.theme-user .bg-primary-dark {
  background-color: #4f546b;
}
abody.theme-user .bg-primary-dark:hover,
abody.theme-user .bg-primary-dark:focus {
  background-color: #393d4e;
}
body.theme-user .bg-primary-dark-op {
  background-color: rgba(79, 84, 107, 0.83);
}
abody.theme-user .bg-primary-dark-op:hover,
abody.theme-user .bg-primary-dark-op:focus {
  background-color: rgba(57, 61, 78, 0.83);
}
body.theme-user .bg-primary-darker {
  background-color: #353847;
}
abody.theme-user .bg-primary-darker:hover,
abody.theme-user .bg-primary-darker:focus {
  background-color: #1f212a;
}
body.theme-user .bg-primary-light {
  background-color: #08ffa7;
}
abody.theme-user .bg-primary-light:hover,
abody.theme-user .bg-primary-light:focus {
  background-color: #00d489;
}
body.theme-user .bg-primary-lighter {
  background-color: #3bffb9;
}
abody.theme-user .bg-primary-lighter:hover,
abody.theme-user .bg-primary-lighter:focus {
  background-color: #08ffa7;
}
body.theme-user .border-primary {
  border-color: #00ca82;
}
body.theme-user .border-primary-op {
  border-color: rgba(0, 202, 130, 0.75);
}
body.theme-user .border-primary-dark {
  border-color: #4f546b;
}
body.theme-user .border-primary-dark-op {
  border-color: rgba(79, 84, 107, 0.83);
}
body.theme-user .border-primary-darker {
  border-color: #353847;
}
body.theme-user .border-primary-light {
  border-color: #08ffa7;
}
body.theme-user .border-primary-lighter {
  border-color: #3bffb9;
}
body.theme-user .btn-default-primary {
  color: #545454;
  background-color: #f5f5f5;
  border-color: #e9e9e9;
}
body.theme-user .btn-default-primary:focus,
body.theme-user .btn-default-primary.focus,
body.theme-user .btn-default-primary:hover {
  color: #fff;
  background-color: #00fda3;
  border-color: #008d5b;
}
body.theme-user .btn-default-primary:active,
body.theme-user .btn-default-primary.active,
.open > .dropdown-togglebody.theme-user .btn-default-primary {
  color: #fff;
  background-color: #00ca82;
  border-color: #005a3a;
}
body.theme-user .btn-default-primary:active:hover,
body.theme-user .btn-default-primary.active:hover,
.open > .dropdown-togglebody.theme-user .btn-default-primary:hover,
body.theme-user .btn-default-primary:active:focus,
body.theme-user .btn-default-primary.active:focus,
.open > .dropdown-togglebody.theme-user .btn-default-primary:focus,
body.theme-user .btn-default-primary:active.focus,
body.theme-user .btn-default-primary.active.focus,
.open > .dropdown-togglebody.theme-user .btn-default-primary.focus {
  color: #fff;
  background-color: #00ca82;
  border-color: #005a3a;
}
body.theme-user .btn-default-primary:active,
body.theme-user .btn-default-primary.active,
.open > .dropdown-togglebody.theme-user .btn-default-primary {
  background-image: none;
}
body.theme-user .btn-default-primary.disabled,
body.theme-user .btn-default-primary[disabled],
fieldset[disabled] body.theme-user .btn-default-primary,
body.theme-user .btn-default-primary.disabled:hover,
body.theme-user .btn-default-primary[disabled]:hover,
fieldset[disabled] body.theme-user .btn-default-primary:hover,
body.theme-user .btn-default-primary.disabled:focus,
body.theme-user .btn-default-primary[disabled]:focus,
fieldset[disabled] body.theme-user .btn-default-primary:focus,
body.theme-user .btn-default-primary.disabled.focus,
body.theme-user .btn-default-primary[disabled].focus,
fieldset[disabled] body.theme-user .btn-default-primary.focus,
body.theme-user .btn-default-primary.disabled:active,
body.theme-user .btn-default-primary[disabled]:active,
fieldset[disabled] body.theme-user .btn-default-primary:active,
body.theme-user .btn-default-primary.disabled.active,
body.theme-user .btn-default-primary[disabled].active,
fieldset[disabled] body.theme-user .btn-default-primary.active {
  background-color: #f5f5f5;
  border-color: #e9e9e9;
}
body.theme-user .btn-default-primary .badge {
  color: #f5f5f5;
  background-color: #545454;
}
body.theme-user .btn-primary {
  color: #fff;
  background-color: #00ca82;
  border-color: #009761;
}
body.theme-user .btn-primary:focus,
body.theme-user .btn-primary.focus,
body.theme-user .btn-primary:hover {
  color: #fff;
  background-color: #00a168;
  border-color: #005a3a;
}
body.theme-user .btn-primary:active,
body.theme-user .btn-primary.active,
.open > .dropdown-togglebody.theme-user .btn-primary {
  color: #fff;
  background-color: #006e47;
  border-color: #002719;
}
body.theme-user .btn-primary:active:hover,
body.theme-user .btn-primary.active:hover,
.open > .dropdown-togglebody.theme-user .btn-primary:hover,
body.theme-user .btn-primary:active:focus,
body.theme-user .btn-primary.active:focus,
.open > .dropdown-togglebody.theme-user .btn-primary:focus,
body.theme-user .btn-primary:active.focus,
body.theme-user .btn-primary.active.focus,
.open > .dropdown-togglebody.theme-user .btn-primary.focus {
  color: #fff;
  background-color: #006e47;
  border-color: #002719;
}
body.theme-user .btn-primary:active,
body.theme-user .btn-primary.active,
.open > .dropdown-togglebody.theme-user .btn-primary {
  background-image: none;
}
body.theme-user .btn-primary.disabled,
body.theme-user .btn-primary[disabled],
fieldset[disabled] body.theme-user .btn-primary,
body.theme-user .btn-primary.disabled:hover,
body.theme-user .btn-primary[disabled]:hover,
fieldset[disabled] body.theme-user .btn-primary:hover,
body.theme-user .btn-primary.disabled:focus,
body.theme-user .btn-primary[disabled]:focus,
fieldset[disabled] body.theme-user .btn-primary:focus,
body.theme-user .btn-primary.disabled.focus,
body.theme-user .btn-primary[disabled].focus,
fieldset[disabled] body.theme-user .btn-primary.focus,
body.theme-user .btn-primary.disabled:active,
body.theme-user .btn-primary[disabled]:active,
fieldset[disabled] body.theme-user .btn-primary:active,
body.theme-user .btn-primary.disabled.active,
body.theme-user .btn-primary[disabled].active,
fieldset[disabled] body.theme-user .btn-primary.active {
  background-color: #00ca82;
  border-color: #009761;
}
body.theme-user .btn-primary .badge {
  color: #00ca82;
  background-color: #fff;
}
body.theme-user .btn-outline {
  color: #646464;
  background-color: #e6e6e6;
  border-color: #009761;
}
body.theme-user .btn-outline:focus,
body.theme-user .btn-outline.focus,
body.theme-user .btn-outline:hover {
  color: #646464;
  background-color: #d2d2d2;
  border-color: #005a3a;
}
body.theme-user .btn-outline:active,
body.theme-user .btn-outline.active,
.open > .dropdown-togglebody.theme-user .btn-outline {
  color: #646464;
  background-color: #b8b8b8;
  border-color: #002719;
}
body.theme-user .btn-outline:active:hover,
body.theme-user .btn-outline.active:hover,
.open > .dropdown-togglebody.theme-user .btn-outline:hover,
body.theme-user .btn-outline:active:focus,
body.theme-user .btn-outline.active:focus,
.open > .dropdown-togglebody.theme-user .btn-outline:focus,
body.theme-user .btn-outline:active.focus,
body.theme-user .btn-outline.active.focus,
.open > .dropdown-togglebody.theme-user .btn-outline.focus {
  color: #646464;
  background-color: #b8b8b8;
  border-color: #002719;
}
body.theme-user .btn-outline:active,
body.theme-user .btn-outline.active,
.open > .dropdown-togglebody.theme-user .btn-outline {
  background-image: none;
}
body.theme-user .btn-outline.disabled,
body.theme-user .btn-outline[disabled],
fieldset[disabled] body.theme-user .btn-outline,
body.theme-user .btn-outline.disabled:hover,
body.theme-user .btn-outline[disabled]:hover,
fieldset[disabled] body.theme-user .btn-outline:hover,
body.theme-user .btn-outline.disabled:focus,
body.theme-user .btn-outline[disabled]:focus,
fieldset[disabled] body.theme-user .btn-outline:focus,
body.theme-user .btn-outline.disabled.focus,
body.theme-user .btn-outline[disabled].focus,
fieldset[disabled] body.theme-user .btn-outline.focus,
body.theme-user .btn-outline.disabled:active,
body.theme-user .btn-outline[disabled]:active,
fieldset[disabled] body.theme-user .btn-outline:active,
body.theme-user .btn-outline.disabled.active,
body.theme-user .btn-outline[disabled].active,
fieldset[disabled] body.theme-user .btn-outline.active {
  background-color: #e6e6e6;
  border-color: #009761;
}
body.theme-user .btn-outline .badge {
  color: #e6e6e6;
  background-color: #646464;
}
body.theme-user .label-primary {
  background-color: #00ca82;
}
body.theme-user .label-primary[href]:hover,
body.theme-user .label-primary[href]:focus {
  background-color: #009761;
}
body.theme-user .badge-primary {
  background-color: #00ca82;
}
body.theme-user .progress-bar-primary {
  background-color: #00ca82;
}
body.theme-user .nav-pills > li.active > a,
body.theme-user .nav-pills > li.active > a:hover,
body.theme-user .nav-pills > li.active > a:focus {
  background-color: #00ca82;
}
body.theme-user .nav-pills > li.active > a > .badge {
  color: #00ca82;
}
body.theme-user .pagination > li > a:hover,
body.theme-user .pagination > li > span:hover,
body.theme-user .pagination > li > a:focus,
body.theme-user .pagination > li > span:focus {
  color: #00ca82;
  -webkit-box-shadow: 0 2px #00ca82;
  box-shadow: 0 2px #00ca82;
}
body.theme-user .pagination > .active > a,
body.theme-user .pagination > .active > span,
body.theme-user .pagination > .active > a:hover,
body.theme-user .pagination > .active > span:hover,
body.theme-user .pagination > .active > a:focus,
body.theme-user .pagination > .active > span:focus {
  color: #00ca82;
  -webkit-box-shadow: 0 2px #00ca82;
  box-shadow: 0 2px #00ca82;
}
body.theme-user .pager li > a:hover,
body.theme-user .pager li > a:focus {
  color: #00ca82;
}
body.theme-user a.list-group-item:hover,
body.theme-user a.list-group-item:focus {
  color: #00ca82;
}
body.theme-user .list-group-item.active,
body.theme-user .list-group-item.active:hover,
body.theme-user .list-group-item.active:focus {
  background-color: #00ca82;
  border-color: #00ca82;
}
body.theme-user .list-group-item.active > .badge {
  color: #00ca82;
}
body.theme-user .table-header-bg > thead > tr > th,
body.theme-user .table-header-bg > thead > tr > td {
  background-color: #00ca82;
  border-bottom-color: #00ca82;
}
body.theme-user .panel-primary {
  border-color: #3bffb9;
}
body.theme-user .panel-primary > .panel-heading {
  color: #00ca82;
  background-color: #5fffc6;
  border-color: #3bffb9;
}
body.theme-user .panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #3bffb9;
}
body.theme-user .panel-primary > .panel-heading .badge {
  color: #5fffc6;
  background-color: #00ca82;
}
body.theme-user .panel-primary > .panel-heading a {
  font-weight: 400;
}
body.theme-user .panel-primary > .panel-heading a:hover,
body.theme-user .panel-primary > .panel-heading a:focus {
  color: #009761;
}
body.theme-user .panel-primary > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #3bffb9;
}
body.theme-user #page-loader:after {
  background-color: #00ca82;
}
body.theme-user #header-navbar {
  background-color: #00ca82;
}
body.theme-user .header-navbar-transparent.header-navbar-fixed.header-navbar-scroll #header-navbar {
  background-color: #4f546b;
}
body.theme-user #page-container,
body.theme-user #sidebar {
  background-color: #353847;
}
body.theme-user #main-container {
  background-color: #f8f8f8;
}
body.theme-user .nav-main-header {
  background-color: #353847;
}
body.theme-user .nav-main-header a {
  color: #3bffb9;
}
body.theme-user .nav-main-header a.link-effect:before {
  background-color: #88ffd4;
}
body.theme-user .nav-main-header a:hover,
body.theme-user .nav-main-header a:focus {
  color: #88ffd4;
}
body.theme-user .nav-main-header a:active {
  color: #3bffb9;
}
@media screen and (min-width: 768px) {
  body.theme-user .nav-main-header {
    background-color: transparent;
  }
  body.theme-user .nav-main-header ul {
    background-color: #4f546b;
  }
}
body.theme-user .form-material.form-material-primary > .form-control:focus {
  -webkit-box-shadow: 0 2px 0 #00ca82;
  box-shadow: 0 2px 0 #00ca82;
}
body.theme-user .form-material.form-material-primary > .form-control:focus + label {
  color: #00ca82;
}
body.theme-user .form-material.form-material-primary > .form-control:focus ~ .input-group-addon {
  color: #00ca82;
  -webkit-box-shadow: 0 2px 0 #00ca82;
  box-shadow: 0 2px 0 #00ca82;
}
body.theme-user .css-checkbox-primary input:checked + span {
  background-color: #00ca82;
  border-color: #00ca82;
}
body.theme-user .css-radio-primary input:checked + span:after,
body.theme-user .switch-primary input:checked + span {
  background-color: #00ca82;
}
body.theme-user .block > .nav-tabs > li > a:hover {
  color: #00ca82;
}
body.theme-user .block > .nav-tabs.nav-tabs-alt > li > a:hover {
  -webkit-box-shadow: 0 2px #00ca82;
  box-shadow: 0 2px #00ca82;
}
body.theme-user .block > .nav-tabs.nav-tabs-alt > li.active > a,
body.theme-user .block > .nav-tabs.nav-tabs-alt > li.active > a:hover,
body.theme-user .block > .nav-tabs.nav-tabs-alt > li.active > a:focus {
  -webkit-box-shadow: 0 2px #00ca82;
  box-shadow: 0 2px #00ca82;
}
body.theme-user .ribbon-primary .ribbon-box {
  background-color: #00ca82;
}
body.theme-user .ribbon-primary.ribbon-bookmark .ribbon-box:before {
  border-color: #00ca82;
  border-left-color: transparent;
}
body.theme-user .ribbon-primary.ribbon-bookmark.ribbon-left .ribbon-box:before {
  border-color: #00ca82;
  border-right-color: transparent;
}
body.theme-user .ribbon-primary.ribbon-modern .ribbon-box:before {
  border-color: #00ca82;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
body.theme-user .ribbon-primary.ribbon-modern.ribbon-bottom .ribbon-box:before {
  border-color: #00ca82;
  border-top-color: transparent;
  border-left-color: transparent;
}
body.theme-user .ribbon-primary.ribbon-modern.ribbon-left .ribbon-box:before {
  border-color: #00ca82;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
body.theme-user .ribbon-primary.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box:before {
  border-color: #00ca82;
  border-top-color: transparent;
  border-right-color: transparent;
}
body.theme-user .irs-bar,
body.theme-user .irs-bar-edge,
body.theme-user .irs-from,
body.theme-user .irs-to,
body.theme-user .irs-single,
body.theme-user .irs-grid-pol {
  background: #00ca82;
}
body.theme-user .dropzone:hover {
  border-color: #00ca82;
}
body.theme-user .dropzone:hover .dz-message {
  color: #00ca82;
}
body.theme-user .datepicker table tr td.active:hover,
body.theme-user .datepicker table tr td.active:hover:hover,
body.theme-user .datepicker table tr td.active.disabled:hover,
body.theme-user .datepicker table tr td.active.disabled:hover:hover,
body.theme-user .datepicker table tr td.active:focus,
body.theme-user .datepicker table tr td.active:hover:focus,
body.theme-user .datepicker table tr td.active.disabled:focus,
body.theme-user .datepicker table tr td.active.disabled:hover:focus,
body.theme-user .datepicker table tr td.active:active,
body.theme-user .datepicker table tr td.active:hover:active,
body.theme-user .datepicker table tr td.active.disabled:active,
body.theme-user .datepicker table tr td.active.disabled:hover:active,
body.theme-user .datepicker table tr td.active.active,
body.theme-user .datepicker table tr td.active:hover.active,
body.theme-user .datepicker table tr td.active.disabled.active,
body.theme-user .datepicker table tr td.active.disabled:hover.active,
body.theme-user .open .dropdown-toggle.datepicker table tr td.active,
body.theme-user .open .dropdown-toggle.datepicker table tr td.active:hover,
body.theme-user .open .dropdown-toggle.datepicker table tr td.active.disabled,
body.theme-user .open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
body.theme-user .datepicker table tr td span.active:hover,
body.theme-user .datepicker table tr td span.active:hover:hover,
body.theme-user .datepicker table tr td span.active.disabled:hover,
body.theme-user .datepicker table tr td span.active.disabled:hover:hover,
body.theme-user .datepicker table tr td span.active:focus,
body.theme-user .datepicker table tr td span.active:hover:focus,
body.theme-user .datepicker table tr td span.active.disabled:focus,
body.theme-user .datepicker table tr td span.active.disabled:hover:focus,
body.theme-user .datepicker table tr td span.active:active,
body.theme-user .datepicker table tr td span.active:hover:active,
body.theme-user .datepicker table tr td span.active.disabled:active,
body.theme-user .datepicker table tr td span.active.disabled:hover:active,
body.theme-user .datepicker table tr td span.active.active,
body.theme-user .datepicker table tr td span.active:hover.active,
body.theme-user .datepicker table tr td span.active.disabled.active,
body.theme-user .datepicker table tr td span.active.disabled:hover.active,
body.theme-user .open .dropdown-toggle.datepicker table tr td span.active,
body.theme-user .open .dropdown-toggle.datepicker table tr td span.active:hover,
body.theme-user .open .dropdown-toggle.datepicker table tr td span.active.disabled,
body.theme-user .open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  background-color: #00ca82;
  border-color: #00ca82;
  color: #ffffff;
}
body.theme-user .datepicker table tr,
body.theme-user .datepicker table td,
body.theme-user .datepicker table th {
  text-align: center;
}
body.theme-user div.tagsinput span.tag {
  background-color: #00ca82;
}
body.theme-user .select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #00ca82;
}
body.theme-user .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #00ca82;
}
body.theme-user .autocomplete-suggestion b {
  color: #00ca82;
}
.text-user {
  color: #00ca82;
}
a.text-user:hover,
a.text-user:active,
a.text-user:focus,
button.text-user:hover,
button.text-user:active,
button.text-user:focus {
  color: #00ca82;
  opacity: .75;
}
li a.text-user:hover {
  opacity: 1;
}
.text-user-dark {
  color: #4f546b;
}
a.text-user-dark:hover,
a.text-user-dark:active,
a.text-user-dark:focus,
button.text-user-dark:hover,
button.text-user-dark:active,
button.text-user-dark:focus {
  color: #4f546b;
  opacity: .75;
}
li a.text-user-dark:hover {
  opacity: 1;
}
.text-user-darker {
  color: #353847;
}
a.text-user-darker:hover,
a.text-user-darker:active,
a.text-user-darker:focus,
button.text-user-darker:hover,
button.text-user-darker:active,
button.text-user-darker:focus {
  color: #353847;
  opacity: .75;
}
li a.text-user-darker:hover {
  opacity: 1;
}
.text-user-light {
  color: #08ffa7;
}
a.text-user-light:hover,
a.text-user-light:active,
a.text-user-light:focus,
button.text-user-light:hover,
button.text-user-light:active,
button.text-user-light:focus {
  color: #08ffa7;
  opacity: .75;
}
li a.text-user-light:hover {
  opacity: 1;
}
.text-user-lighter {
  color: #3bffb9;
}
a.text-user-lighter:hover,
a.text-user-lighter:active,
a.text-user-lighter:focus,
button.text-user-lighter:hover,
button.text-user-lighter:active,
button.text-user-lighter:focus {
  color: #3bffb9;
  opacity: .75;
}
li a.text-user-lighter:hover {
  opacity: 1;
}
.bg-user {
  background-color: #00ca82;
}
a.bg-user:hover,
a.bg-user:focus {
  background-color: #009761;
}
.bg-user-op {
  background-color: rgba(0, 202, 130, 0.75);
}
a.bg-user-op:hover,
a.bg-user-op:focus {
  background-color: rgba(0, 151, 97, 0.75);
}
.bg-user-dark {
  background-color: #4f546b;
}
a.bg-user-dark:hover,
a.bg-user-dark:focus {
  background-color: #393d4e;
}
.bg-user-dark-op {
  background-color: rgba(79, 84, 107, 0.83);
}
a.bg-user-dark-op:hover,
a.bg-user-dark-op:focus {
  background-color: rgba(57, 61, 78, 0.83);
}
.bg-user-darker {
  background-color: #353847;
}
a.bg-user-darker:hover,
a.bg-user-darker:focus {
  background-color: #1f212a;
}
.bg-user-light {
  background-color: #08ffa7;
}
a.bg-user-light:hover,
a.bg-user-light:focus {
  background-color: #00d489;
}
.bg-user-lighter {
  background-color: #3bffb9;
}
a.bg-user-lighter:hover,
a.bg-user-lighter:focus {
  background-color: #08ffa7;
}
.border-user {
  border-color: #00ca82;
}
.border-user.block-bordered {
  border-color: #00ca82;
}
.border-user-op {
  border-color: rgba(0, 202, 130, 0.75);
}
.border-user-op.block-bordered {
  border-color: rgba(0, 202, 130, 0.75);
}
.border-user-dark {
  border-color: #4f546b;
}
.border-user-dark.block-bordered {
  border-color: #4f546b;
}
.border-user-dark-op {
  border-color: rgba(79, 84, 107, 0.83);
}
.border-user-dark-op.block-bordered {
  border-color: rgba(79, 84, 107, 0.83);
}
.border-user-darker {
  border-color: #353847;
}
.border-user-darker.block-bordered {
  border-color: #353847;
}
.border-user-light {
  border-color: #08ffa7;
}
.border-user-light.block-bordered {
  border-color: #08ffa7;
}
.border-user-lighter {
  border-color: #3bffb9;
}
.border-user-lighter.block-bordered {
  border-color: #3bffb9;
}
