//
// Page Styles: NHI Modal
// --------------------------------------------------
#patientRequestNHIBlock {
	input.hkjs--untouched,
	.select2-pristine + .select2-container {
		color: #aaa;
		.select2-selection__rendered{
			color: #aaa;
		}
	}

}