//
// Color Theme - Modern
// --------------------------------------------------

// Variables and Mixins
@import "../variables.less";
@import "../mixins.less";

// Generate color theme
body.theme-modern{
	.color-theme(@theme-modern-base; @theme-modern-light; @theme-modern-lighter; @theme-modern-dark; @theme-modern-darker; @theme-modern-body);
}